import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getIncompleteInvoices } from "../../../redux/api/expedition.api";
import TableWidget from "../../../components/tables/TableWidget";
import {
  getIncompleteInvoicesTableData,
  incompleteInvoicesTableHeader,
} from "./tableConfig/incompleteInvoicesTableConfig";

function IncompleteInvoices() {
  const dispatch = useAppDispatch();

  const {
    expedition: {
      incompleteInvoices: {
        data: incompleteInvoicesData,
        isLoadingIncompleteInvoices,
      },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  useEffect(() => {
    dispatch(getIncompleteInvoices());
  }, [dispatch]);

  return (
    <TableWidget
      tableTitle={"Facturi fara destinatar"}
      tableHeaderData={incompleteInvoicesTableHeader}
      tableItemsData={getIncompleteInvoicesTableData(incompleteInvoicesData)}
      tableLoading={isLoadingIncompleteInvoices}
      showSearchForCategories={false}
      withTablePagination={false}
      borderedRow
    />
  );
}

export default IncompleteInvoices;
