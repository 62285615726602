import React from "react";
import TableWidget from "../../../components/tables/TableWidget";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchVerificationZones } from "../../../redux/api/picking.api";

import {
  verificationZonesTableHeader,
  getVerificationZonesTableData,
} from "./components/tableConfigs/verificationZonesTableConfig";
import { TableActions } from "../../../components/tables/tableContext/TableContext";

import VerificationZonesActionButton from "./components/TableActionsButtonsComponents/VerificationZonesActionButton";

function VerificationZones() {
  const dispatch = useAppDispatch();
  const {
    picking: {
      verificationZones: {
        data: verificationZonesData,
        isLoading: isLoadingVerifycationZones,
      },
    },
  } = useAppSelector((state) => ({
    picking: state.picking,
  }));

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableFilter
    ) {
      dispatch(fetchVerificationZones());
    }
  };

  return (
    <TableWidget
      tableTitle={"Zone de verificare"}
      tableHeaderData={verificationZonesTableHeader}
      tableItemsData={getVerificationZonesTableData(verificationZonesData)}
      borderedRow
      tableLoading={isLoadingVerifycationZones}
      ActionButtonsComponent={(props: any) => (
        <VerificationZonesActionButton {...props} />
      )}
      handleTableActions={handleTableActions}
      withTablePagination={false}
    />
  );
}

export default VerificationZones;
