import { Box } from "@mui/material";
import { useEffect } from "react";
import { getReturningTasks } from "../../../redux/api/returning.api";
import { clearReturningTasks } from "../../../redux/reducer/returning.reducer";
import { TableActions } from "../../../components/tables/tableContext/TableContext";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import ReturningTasksActionButtons from "./components/ReturningTasksActionButtons";
import TableWidget from "../../../components/tables/TableWidget";
import {
  getReturningTableData,
  returningTableHeader,
} from "./tableConfigs/returningTasksTableConfig";
import ReturningTasksActionButton from "./components/TableActionsButtonsComponents/ReturningTasksActionButton";

function ReturningTasks() {
  const dispatch = useAppDispatch();

  const {
    returning: {
      returningTasks: { data: avaibleReturns, isLoadingReturningTasks },
    },
  } = useAppSelector((state) => ({
    returning: state.returning,
  }));

  useEffect(() => {
    return () => {
      dispatch(clearReturningTasks());
    };
  }, [dispatch]);

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableFilter?.status &&
      action.tableSearch?.deliveryPoint != null
    ) {
      dispatch(
        getReturningTasks({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          status: action.tableFilter?.status,
          deliveryPoint: action.tableSearch?.deliveryPoint,
        }),
      );
    }
  };

  return (
    <Box>
      <ReturningTasksActionButtons />
      <TableWidget
        tableTitle="Retururi"
        tableHeaderData={returningTableHeader}
        tableItemsData={getReturningTableData(avaibleReturns.retururi)}
        tableLoading={isLoadingReturningTasks}
        handleTableActions={handleTableActions}
        totalItems={avaibleReturns.nrTotalRetururi}
        withGlobalSearch={false}
        ActionButtonsComponent={(props: any) => (
          <ReturningTasksActionButton {...props} />
        )}
      />
    </Box>
  );
}

export default ReturningTasks;
