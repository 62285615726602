import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { BorderouHistoryListInterface } from "../../../../redux/interfaces/expedition.interface";
import { dateToString } from "../../../../utils/dateAndTime";

export const borderouHistoryTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Id borderou",
    value: "idBorderou",
    minW: 125,
  },
  {
    label: "Nod",
    value: "nod",
    withSearch: true,
    minW: 125,
  },
  {
    label: "Data creare borderou",
    value: "createdAt",
    minW: 125,
  },
  {
    label: "Status",
    value: "validat",
    minW: 125,
  },
];

export const getBorderouHistoryTableData: any = (
  borderouHistory: BorderouHistoryListInterface[],
) =>
  borderouHistory.map((item) => {
    return {
      id: item.idBorderou,
      crudData: item,
      data: [
        {
          title: item.idBorderou,
        },
        {
          title: item.nod ? item.nod : "-",
        },
        {
          badges: [
            {
              badgeText: dateToString(item.createdAt),
              badgeColor: "light-info",
            },
          ],
        },
        {
          badges: [
            {
              badgeText: item.validat ? "Validat" : "Nevalidat",
              badgeColor: item.validat ? "light-success" : "light-danger",
            },
          ],
        },
      ],
    };
  });
