import { useEffect } from "react";
import TableWidget from "../../../components/tables/TableWidget";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getWorkingRoutesNodes } from "../../../redux/api/expedition.api";
import {
  getNodesTableData,
  nodesTableHeader,
} from "./tableConfigs/expeditionNodesTableConfig";

import NodesRouteTableActionButtons from "./TableActionsButtonsComponents/NodesTableActionButtons";

function NodesRoute() {
  const dispatch = useAppDispatch();

  const {
    expedition: {
      workingRoutesNodes: {
        data: workingRoutesNodesData,
        isLoadingWorkingRoutesNodes,
      },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  useEffect(() => {
    dispatch(getWorkingRoutesNodes());
  }, [dispatch]);

  return (
    <TableWidget
      tableTitle={"Noduri"}
      tableHeaderData={nodesTableHeader}
      tableItemsData={getNodesTableData(workingRoutesNodesData)}
      tableLoading={isLoadingWorkingRoutesNodes}
      ActionButtonsComponent={NodesRouteTableActionButtons}
      showSearchForCategories={false}
      borderedRow
    />
  );
}

export default NodesRoute;
