import { useCallback, useEffect } from "react";
import { useAppSelector } from "../../../../../redux/hooks";
import { QrCodeObjectInterface } from "../../../../../helpers/qrCodeFormater";

const headerScrollOffset = 100;

function useVerifyCommandProductsConfig({
  qrCodeParams,
}: {
  qrCodeParams: QrCodeObjectInterface | null;
}) {
  const {
    avizPicking: {
      verifyCommand: { verificationZone },
      verifyCommandProducts: {
        data: verifyCommandProductsData,
        isLoading: isLoadingVerifyCommandProducts,
      },
    },
  } = useAppSelector((state) => ({
    avizPicking: state.avizPicking,
  }));

  const searchedProductSerialNumber = qrCodeParams?.serialNumber ?? "";

  const handleScrollToItem = useCallback((itemId: string) => {
    const tableElementIdTop = document
      .getElementById(itemId)
      ?.getBoundingClientRect().top;
    const bodyRectTop = document.body.getBoundingClientRect().top;

    if (!tableElementIdTop) {
      return;
    }

    window.scrollTo({
      behavior: "smooth",
      top: tableElementIdTop - bodyRectTop - headerScrollOffset,
    });
  }, []);

  useEffect(() => {
    if (searchedProductSerialNumber) {
      handleScrollToItem(searchedProductSerialNumber);
    }
  }, [handleScrollToItem, searchedProductSerialNumber]);

  return {
    verifyCommandProductsData,
    isLoadingVerifyCommandProducts,
    verificationZone,
  };
}

export default useVerifyCommandProductsConfig;
