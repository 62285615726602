import { useEffect, useRef, useState } from "react";
import { Step, StepLabel, Stepper } from "@mui/material";

import ContentCard from "../../../components/misc/ContentCard";
import TextInput from "../../../components/misc/TextInput";

import {
  getPackingBoxes,
  getVerifyCommandProducts,
} from "../../../redux/api/picking.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  resetVerifyCommandProducts,
  setVerifyCommand,
} from "../../../redux/reducer/picking.reducer";

import VerifyCommandProducts from "./VerifyCommandProducts";
import VerifyCommandHeader from "./VerifyCommandProducts/components/VerifyCommandHeader";
import LastVerifiedCommand from "./VerifyCommandProducts/components/LastVerifiedCommand";

import BoxesNumber from "./BoxesNumber";
import ScanPlasticBoxes from "./ScanPlasticBoxes";
import Text from "../../../styleguide/Text";

const steps = [
  { label: "Verificare produs" },
  { label: "Cutii ambalare" },
  { label: "Scanare cutii plastic", optional: "Optional" },
];

function VerifyCommand() {
  const verifyCommandInputRef = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();

  const {
    picking: {
      verifyCommand: { verificationZone, step },
      verifyCommandProducts: {
        errorMessage: errorMessageVerifyCommandProducts,
      },
    },
  } = useAppSelector((state) => ({
    picking: state.picking,
  }));

  const [scannedCode, setScannedCode] = useState<string | number | null>(null);

  useEffect(() => {
    if (!verificationZone) {
      dispatch(resetVerifyCommandProducts());

      verifyCommandInputRef.current?.focus();
    }
  }, [dispatch, verificationZone]);

  useEffect(() => {
    return () => {
      dispatch(resetVerifyCommandProducts());
      dispatch(setVerifyCommand({ verificationZone: "", step: 0 }));
    };
  }, [dispatch]);

  const handleScanSubmit = (value: string) => {
    dispatch(
      setVerifyCommand({
        verificationZone: value,
        step: 0,
      }),
    );
    dispatch(getPackingBoxes());
    dispatch(getVerifyCommandProducts(value));
    setScannedCode("");
  };

  return (
    <ContentCard>
      <LastVerifiedCommand />
      <TextInput
        externalInputRef={verifyCommandInputRef}
        label="Verifica comanda"
        id="scannInput"
        name="scannInput"
        value={scannedCode}
        onChange={setScannedCode}
        onKeyDown={(event) => {
          // @ts-ignore
          const targetValue = event.target.value;
          if (targetValue && event.key == "Enter") {
            handleScanSubmit(targetValue);
          }
        }}
        autoFocus
      />

      {errorMessageVerifyCommandProducts && (
        <Text variant="body1" className="my-7 text-center text-danger">
          {errorMessageVerifyCommandProducts}
        </Text>
      )}

      {!errorMessageVerifyCommandProducts && verificationZone && (
        <>
          <VerifyCommandHeader />
          <Stepper activeStep={step} alternativeLabel className="mb-6">
            {steps.map(({ label, optional }, index) => {
              return (
                <Step key={index}>
                  <StepLabel optional={optional}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {step === 0 && <VerifyCommandProducts />}
          {step === 1 && <BoxesNumber />}
          {step === 2 && <ScanPlasticBoxes />}
        </>
      )}
    </ContentCard>
  );
}

export default VerifyCommand;
