import {
  getCurrentDate,
  getCurrentTime,
  getLastDayOfMonth,
} from "../utils/dateAndTime";

const BARCODE = "barcode";
const QRCODE = "qrcode";

const serialNumberQrCode = "01";
const expirationDateQrCode = "17";
const lotQrCode = "10";
const uniqSeriesQrCode = "21";
const specialCharacterForAlgorithm = "~";

export const serialNumberStr = "serialNumber";
export const expirationDateStr = "expirationDate";
export const lotStr = "lot";
export const uniqSeriesStr = "uniqSeries";
export const scanningDate = "scanningDate";
export const scanningTime = "scanningTime";
export const rawQrCode = "rawQrCode";

const specialCharacteristicCharactersLength = 2;

export interface QrCodeObjectInterface {
  rawQrCode: string;
  serialNumber: string;
  expirationDate: string;
  lot: string;
  uniqSeries: string;
  scanningDate: string;
  scanningTime: string;
  isQrCode: boolean;
  isBarCode: boolean;
  isValidCode: boolean;
}

const checkQrType = (qrCode: string) => {
  if (qrCode.length < 20) {
    return BARCODE;
  }
  return QRCODE;
};

const indexOfSpecialCharacter = (qrCode: string) => {
  return qrCode.indexOf(specialCharacterForAlgorithm);
};

const formatDateToValidFormat = (date: string) => {
  const parts = date.match(/.{1,2}/g);

  // complete the year with 20
  if (parts && parts[0]) {
    parts[0] = `20${parts[0]}`;
  }

  // if the day is 00, we set it to the last day of the month
  if (parts && parts[2] === "00") {
    const dateWithoutDay = `${parts[0]}-${parts[1]}`;
    parts[2] = getLastDayOfMonth(dateWithoutDay).toString();
  }

  //@ts-ignore
  const newDate = parts?.join("-") ?? "";
  return newDate;
};

const getValueAndSubstringFromQrCode = (
  qrCode: string,
): {
  name: string;
  value: string;
  qrCodeSubstring: string;
  specialCharacter: string;
} => {
  const qrCodeObject = {
    name: "",
    value: "",
    qrCodeSubstring: "",
    specialCharacter: qrCode.substring(
      0,
      specialCharacteristicCharactersLength,
    ),
  };

  switch (qrCode.substring(0, specialCharacteristicCharactersLength)) {
    case serialNumberQrCode:
      qrCodeObject.name = serialNumberStr;
      qrCodeObject.value = qrCode.substring(
        specialCharacteristicCharactersLength,
        16,
      );
      qrCodeObject.qrCodeSubstring = qrCode.substring(16);
      break;
    case expirationDateQrCode:
      qrCodeObject.name = expirationDateStr;
      qrCodeObject.value = formatDateToValidFormat(
        qrCode.substring(specialCharacteristicCharactersLength, 8),
      );
      qrCodeObject.qrCodeSubstring = qrCode.substring(8);
      break;
    case lotQrCode:
      qrCodeObject.name = lotStr;
      if (indexOfSpecialCharacter(qrCode) !== -1) {
        qrCodeObject.value = qrCode.substring(
          specialCharacteristicCharactersLength,
          indexOfSpecialCharacter(qrCode),
        );
        qrCodeObject.qrCodeSubstring = qrCode.substring(
          indexOfSpecialCharacter(qrCode) + 1,
        );
      } else {
        qrCodeObject.value = qrCode.substring(
          specialCharacteristicCharactersLength,
          20,
        );
        qrCodeObject.qrCodeSubstring = qrCode.substring(20);
      }
      break;
    case uniqSeriesQrCode:
      qrCodeObject.name = uniqSeriesStr;

      if (indexOfSpecialCharacter(qrCode) !== -1) {
        qrCodeObject.value = qrCode.substring(
          specialCharacteristicCharactersLength,
          indexOfSpecialCharacter(qrCode),
        );
        qrCodeObject.qrCodeSubstring = qrCode.substring(
          indexOfSpecialCharacter(qrCode) + 1,
        );
      } else {
        qrCodeObject.value = qrCode.substring(
          specialCharacteristicCharactersLength,
          20,
        );
        qrCodeObject.qrCodeSubstring = qrCode.substring(20);
      }
      break;
  }

  return {
    ...qrCodeObject,
  };
};

const qrCodeObject: QrCodeObjectInterface = {
  [rawQrCode]: "",
  [serialNumberStr]: "",
  [expirationDateStr]: "",
  [lotStr]: "",
  [uniqSeriesStr]: "",
  [scanningDate]: getCurrentDate(),
  [scanningTime]: getCurrentTime(),
  isQrCode: false,
  isBarCode: false,
  isValidCode: false,
};

export const getQrCodeData = (
  qrValue?: string | number,
): QrCodeObjectInterface | null => {
  if (!qrValue) {
    return null;
  }

  const qrValueString = qrValue.toString();

  // we exclude some characters to be replaced by the special character
  // right now we exclude strings, numbers and "-","_" and "/"" character
  let qrData = qrValue
    .toString()
    .trim()
    // accepted special characters
    .replace(/[^\w/-/-/_/./]/g, specialCharacterForAlgorithm);

  if (qrData.substring(0, 1) === specialCharacterForAlgorithm) {
    qrData = qrData.substring(1);
  }
  if (qrData.substring(qrData.length - 1) === specialCharacterForAlgorithm) {
    qrData = qrData.substring(0, qrData.length - 1);
  }

  // if the qr code is shorter than 20 characters, it is a barcode
  const serialNumberObject: QrCodeObjectInterface = {
    ...qrCodeObject,
    [rawQrCode]: qrValueString,
    [serialNumberStr]: qrData,
    isBarCode: true,
    isValidCode: true,
  };

  if (checkQrType(qrData) === BARCODE) {
    return serialNumberObject;
  }

  const getFirstQrData = getValueAndSubstringFromQrCode(qrData);

  const getSecondQrValue = getValueAndSubstringFromQrCode(
    getFirstQrData.qrCodeSubstring,
  );

  const getThirdQrValue = getValueAndSubstringFromQrCode(
    getSecondQrValue.qrCodeSubstring,
  );

  const getFourthQrValue = getValueAndSubstringFromQrCode(
    getThirdQrValue.qrCodeSubstring,
  );

  if (
    !getFirstQrData.value ||
    !getSecondQrValue.value ||
    !getThirdQrValue.value
  ) {
    return {
      ...qrCodeObject,
      [getFirstQrData.name]: getFirstQrData.value,
      [getSecondQrValue.name]: getSecondQrValue.value,
      [getThirdQrValue.name]: getThirdQrValue.value,
      [getFourthQrValue.name]: getFourthQrValue.value,
      [rawQrCode]: qrValueString,
      isValidCode: false,
    };
  }

  const qrCodeObjectClone: QrCodeObjectInterface = {
    ...qrCodeObject,
    [rawQrCode]: qrValueString,
    [getFirstQrData.name]: getFirstQrData.value,
    [getSecondQrValue.name]: getSecondQrValue.value,
    [getThirdQrValue.name]: getThirdQrValue.value,
    [getFourthQrValue.name]: getFourthQrValue.value,
    isQrCode: true,
    isValidCode: true,
  };

  return qrCodeObjectClone;
};
