import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ContentCard from "../../../components/misc/ContentCard";
import TransferList from "../../../components/misc/TransferableLists";
import Text from "../../../styleguide/Text";
import CustomButton from "../../../components/CustomButton";
import Dropdown, { DropdownValue } from "../../../components/misc/Dropdown";

import useRoutesHelper from "../../../hooks/useRoutesHelper";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getDeliveryInvoices,
  postSplitDeliveryArrangements,
} from "../../../redux/api/picking.api";
import { getScheduleWorkingPoints } from "../../../redux/api/expedition.api";
import { removeDuplicatesFromArray } from "../../../helpers/arrayHelper";

function SplitInvoices() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    routeParams: { op, delivery },
  }: {
    routeParams: { op: string; delivery: string };
  } = useRoutesHelper() || {};

  const {
    picking: {
      deliveryInvoices: {
        data: allInvoices,
        isLoading: isLoadingDeliveryInvoices,
      },
    },
    expedition: {
      scheduleWorkingPoints: {
        data: scheduleWorkingPointsData,
        isLoadingScheduleWorkingPoints,
      },
    },
  } = useAppSelector((state) => ({
    picking: state.picking,
    expedition: state.expedition,
  }));

  const [leftList, setLeftList] = useState<any[]>([]);
  const [rightList, setRightList] = useState<any[]>([]);
  const [isLoadingSplitting, setIsLoadingSplitting] = useState(false);

  const [valueZone, setValueZone] = useState<null | DropdownValue>(null);

  useEffect(() => {
    if (!op || !delivery) return;
    dispatch(getDeliveryInvoices({ params: { op } }));
  }, [dispatch, op, delivery]);

  useEffect(() => {
    if (allInvoices.length === 0) return;
    const availableInvoices = allInvoices.map((invoice) => invoice.act);
    setLeftList(availableInvoices);
  }, [allInvoices]);

  useEffect(() => {
    if (delivery) {
      dispatch(
        getScheduleWorkingPoints({
          page: 1,
          perPage: 50,
          deliveryPoint: delivery,
          route: "",
        }),
      );
    }
  }, [dispatch, delivery]);

  useEffect(() => {
    if (scheduleWorkingPointsData.workingPoints.length === 0) return;
    {
      const selectedValue = scheduleWorkingPointsData.workingPoints[0];
      if (selectedValue) {
        setValueZone((prev) => {
          if (prev === null) {
            return {
              id: selectedValue.idPctLucru,
              label: selectedValue.pctDeLivr,
            };
          } else {
            return prev;
          }
        });
      }
    }
  }, [scheduleWorkingPointsData.workingPoints]);

  const handleOnchangeZonesDropdown = useCallback(
    (value: string | number) => {
      dispatch(
        getScheduleWorkingPoints({
          page: 1,
          perPage: 50,
          deliveryPoint: value?.toString() || "",
          route: "",
        }),
      );
    },
    [dispatch],
  );

  const handleValidateSplit = (type: "split" | "disable") => {
    setIsLoadingSplitting(true);
    const callBack = () => {
      setIsLoadingSplitting(false);
      navigate(-1);
    };

    dispatch(
      postSplitDeliveryArrangements({
        params: { op, type, facturi: rightList, valueZone },
        callBack,
      }),
    );
  };

  let dropdownValues: DropdownValue[] =
    scheduleWorkingPointsData.workingPoints.map((item) => ({
      id: item.idPctLucru,
      label: item.pctDeLivr,
    }));

  if (valueZone) {
    dropdownValues = removeDuplicatesFromArray(
      [valueZone, ...dropdownValues],
      "id",
    );
  }

  return (
    <ContentCard
      CardHeader={() => {
        return (
          <div className="d-flex align-items-center">
            <Text variant="h5" gutterBottom className="me-1 w-100">
              Split facturi din comanda {op} pentru punctul de livrare{" "}
              {/* {delivery} */}
            </Text>
            <Dropdown
              className="w-100"
              data={dropdownValues}
              singleValue={valueZone}
              setSingleValue={setValueZone}
              onChange={handleOnchangeZonesDropdown}
              isLoading={isLoadingScheduleWorkingPoints}
            />
          </div>
        );
      }}>
      <TransferList
        isLoading={isLoadingDeliveryInvoices}
        leftListTitle="Facturi disponibile "
        rightListTitle="Facturi adaugate "
        leftListItems={leftList}
        rightListItems={rightList}
        listCrudItems={allInvoices}
        setRightList={setRightList}
        setLeftList={setLeftList}
        disabled={false}
      />
      <div className="d-flex justify-content-end mt-4">
        <CustomButton
          variant="outlined"
          className="me-2"
          loading={isLoadingSplitting}
          disabled={isLoadingDeliveryInvoices || isLoadingSplitting}
          withConfirmationModal={{
            modalTitle: "Dezactiveaza facturi",
            modalSubtitle: "Esti sigur ca vrei sa dezactivezi facturile?",
            modalLeftButtonVariant: "outlined",
            modalRightButtonOnClick: () => handleValidateSplit("disable"),
          }}>
          Dezactiveaza facturi
        </CustomButton>
        <CustomButton
          variant="contained"
          className="me-2"
          loading={isLoadingSplitting}
          disabled={isLoadingDeliveryInvoices || isLoadingSplitting}
          withConfirmationModal={{
            modalTitle: "Imparte comanda",
            modalSubtitle: "Esti sigur ca vrei sa imparti comanda?",
            modalLeftButtonVariant: "outlined",
            modalRightButtonOnClick: () => handleValidateSplit("split"),
          }}>
          Imparte comanda
        </CustomButton>
      </div>
    </ContentCard>
  );
}

export default SplitInvoices;
