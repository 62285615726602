import { useAppDispatch } from "../../../../../redux/hooks";
import { fetchUnlockZone } from "../../../../../redux/api/picking.api";

import CustomButton from "../../../../../components/CustomButton";

function VerificationZonesActionButton({ crudData }: any) {
  const dispatch = useAppDispatch();

  const handleActivateCell = () => {
    dispatch(
      fetchUnlockZone({
        idZona: crudData.idZona,
      }),
    );
    return;
  };

  return (
    <div>
      {crudData.status && (
        <CustomButton
          variant="contained"
          className="mt-1 me-1"
          withConfirmationModal={{
            modalLeftButtonVariant: "outlined",
            modalTitle: `Doriti sa deblocati zona ${crudData.codZona}?`,
            modalRightButtonOnClick: handleActivateCell,
          }}>
          Deblocheaza
        </CustomButton>
      )}
    </div>
  );
}

export default VerificationZonesActionButton;
