import React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getProductHistory } from "../../../redux/api/inventory.api";

import useRoutesHelper from "../../../hooks/useRoutesHelper";

import TableWidget from "../../../components/tables/TableWidget";
import { TableActions } from "../../../components/tables/tableContext/TableContext";

import {
  getProductHistoryTableData,
  productHistoryTableHeader,
} from "./tableConfigs/productHistoryConfig";

import ProductHeaderTable from "./components/ProductHeaderTable";

function ProductHistory() {
  const dispatch = useAppDispatch();
  const { routeParams } = useRoutesHelper();

  const { codProdus, lot, dataExpirare, numeProdusErp } = routeParams || {};

  const {
    inventory: {
      productHistory: {
        data: { data: productHistory, total },
        isLoading,
      },
    },
  } = useAppSelector((state) => ({
    inventory: state.inventory,
  }));

  const handleTableActions = (action: TableActions) => {
    if (action.tablePagination.page && action.tablePagination.rowsPerPage) {
      dispatch(
        getProductHistory({
          page: action.tablePagination.page,
          per_page: action.tablePagination.rowsPerPage,
          lot: action.tableSearch?.lot,
          codProdus: action.tableSearch?.codProdus,
          dataExpirare: action.tableSearch?.dataExpirare,
        }),
      );
    }
  };

  return (
    <>
      <ProductHeaderTable
        codProdus={codProdus}
        lot={lot}
        dataExpirare={dataExpirare}
        numeProdusErp={numeProdusErp}
      />

      <TableWidget
        tableHeaderData={productHistoryTableHeader}
        tableItemsData={getProductHistoryTableData(productHistory)}
        totalItems={total}
        tableLoading={isLoading}
        handleTableActions={handleTableActions}
        withGlobalSearch={false}
        withGlobalFilter={false}
        withTablePagination
      />
    </>
  );
}

export default ProductHistory;
