import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../../redux/hooks";

import {
  getPrintCommandInvoice,
  getPrintCommandInvoiceFromHistory,
} from "../../../redux/api/avizPicking.api";

import ContentCard from "../../../components/misc/ContentCard";
import TextInput from "../../../components/misc/TextInput";
import CustomButton from "../../../components/CustomButton";
import Text from "../../../styleguide/Text";

import withAwbBoxesNumberModal from "./components/withAwbBoxesNumberModal";

const AvizPickingPrintCommandInvoice = ({
  handleShowPrintAwbBoxesModal,
}: {
  handleShowPrintAwbBoxesModal?: (
    cellScannedValue?: string | number | null,
  ) => void;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const inputRef = useRef<HTMLInputElement>(null);

  const [scannedCode, setScannedCode] = useState<string | number | null>(null);
  const [persistedScannedCode, setPersistedScannedCode] = useState<
    string | number
  >("");

  const handlePrintInvoice = () => {
    dispatch(getPrintCommandInvoice(persistedScannedCode));
  };

  const handlePrintInvoiceFromHistory = () => {
    dispatch(getPrintCommandInvoiceFromHistory(persistedScannedCode));
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <ContentCard>
      <TextInput
        label="Printeaza comanda cu aviz"
        id="scannInput"
        name="scannInput"
        value={scannedCode}
        onChange={setScannedCode}
        autoFocus
        externalInputRef={inputRef}
        onKeyDown={(event) => {
          // @ts-ignore
          const targetValue = event.target.value;
          if (targetValue && event.key == "Enter") {
            setPersistedScannedCode(targetValue);
            setScannedCode("");
          }
        }}
      />

      {!!persistedScannedCode && (
        <div>
          <Text variant="h1" className="my-7 text-center">
            Cod comanda {persistedScannedCode}
          </Text>
        </div>
      )}
      <div className="d-flex justify-content-between">
        <CustomButton variant="outlined" onClick={handleGoBack}>
          Anuleaza
        </CustomButton>
        {!!persistedScannedCode && (
          <div>
            <CustomButton
              variant="contained"
              className="me-3"
              withConfirmationModal={{
                modalTitle: `Doriti sa tipariti comanda ${persistedScannedCode}?`,
                modalLeftButtonVariant: "outlined",
                modalRightButtonOnClick: handlePrintInvoice,
              }}>
              Tipareste facturi
            </CustomButton>
            <CustomButton
              variant="contained"
              className="me-3"
              onClick={() =>
                handleShowPrintAwbBoxesModal &&
                handleShowPrintAwbBoxesModal(persistedScannedCode)
              }>
              Tipareste AWB
            </CustomButton>
            <CustomButton
              variant="contained"
              color="warning"
              className="me-3"
              withConfirmationModal={{
                modalSubtitle: `Doriti sa tipariti din arhiva facturile pentru comanda ${persistedScannedCode}?`,
                modalTitle: "Tiparire facturi din arhiva",
                modalLeftButtonVariant: "outlined",
                modalRightButtonOnClick: handlePrintInvoiceFromHistory,
              }}>
              Tipareste din arhiva
            </CustomButton>
          </div>
        )}
      </div>
    </ContentCard>
  );
};

export default withAwbBoxesNumberModal(AvizPickingPrintCommandInvoice);
