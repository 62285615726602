import { useEffect, useState } from "react";

import TableWidget from "../../../components/tables/TableWidget";
import { TableActions } from "../../../components/tables/tableContext/TableContext";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import { postBorderouHistory } from "../../../redux/api/expedition.api";
import objectParsers from "../../../utils/objectParsers";

import {
  borderouHistoryTableHeader,
  getBorderouHistoryTableData,
} from "./tableConfig/borderouHistoryTableConfig";

import BorderouHistoryTableActionsButtons from "./TableActionsButtonsComponents/BorderouHistoryTableActionsButtons";
import BorderouHistoryDataHeader from "./components/BorderouHistoryDataHeader";

function BorderouHistory() {
  const dispatch = useAppDispatch();

  const {
    expedition: {
      borderouHistory: { data: borderouHistoryData, isLoadingBorderouHistory },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const [searchData, setSearchData] = useState<{
    nod: string;
    borderouCreationDate: string;
  }>({ nod: "", borderouCreationDate: "" });

  useEffect(() => {
    if (!searchData.borderouCreationDate) return;

    dispatch(
      postBorderouHistory({
        params: {
          dataBorderou: searchData.borderouCreationDate,
        },
      }),
    );
  }, [dispatch, searchData.borderouCreationDate]);

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableSearch
    ) {
      const { borderouCreationDate, nod } =
        objectParsers.objectValuesToLowerCase(action.tableSearch);
      if (borderouCreationDate) {
        setSearchData({
          nod,
          borderouCreationDate,
        });
      }
    }
  };

  const borderouHistoryFilteredData = borderouHistoryData.filter((item) => {
    const { nod } = searchData;

    const { nod: nodToLowerCase } = objectParsers.objectValuesToLowerCase({
      nod: item.nod,
    });

    if (nod) {
      return nodToLowerCase.includes(nod);
    }
    return true;
  });

  return (
    <TableWidget
      tableTitle="Istoric borderouri"
      tableHeaderData={borderouHistoryTableHeader}
      tableItemsData={getBorderouHistoryTableData(borderouHistoryFilteredData)}
      tableLoading={isLoadingBorderouHistory}
      handleTableActions={handleTableActions}
      ActionButtonsComponent={BorderouHistoryTableActionsButtons}
      withTablePagination={false}
      CustomHeaderComponent={BorderouHistoryDataHeader}
    />
  );
}

export default BorderouHistory;
