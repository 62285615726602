import { ProductHistoryInterface } from "../../../../redux/interfaces/inventory.interface";

import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";

import { getDateAndTime } from "../../../../utils/dateAndTime";

export const productHistoryTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Cantitate miscata",
    value: "cantMiscata",
    minW: 50,
  },
  {
    label: "Cantitate",
    value: "totalCantitate",
    minW: 50,
  },
  {
    label: "Locatie",
    value: "location",
    minW: 100,
  },
  {
    label: "Utilizator",
    value: "userName",
    minW: 100,
  },
  {
    label: "Data actiune",
    value: "dataActiune",
    minW: 100,
  },
];

export const getProductHistoryTableData: any = (
  productHistoryList: ProductHistoryInterface[],
) =>
  productHistoryList?.map((product) => {
    const location = [
      product.cladire,
      product.rand,
      product.coloana,
      product.polita,
      product.celula,
      product.alee,
      product.insula,
    ]
      .filter(Boolean)
      .join("-");
    return {
      id: product.id_arh_activCel || product.idActivCel,
      crudData: product,
      data: [
        {
          title: product.cantMiscata,
        },
        {
          title: product.cantitate,
        },
        {
          title: location,
        },
        {
          title: product.userName,
        },
        {
          badges: [
            {
              badgeText: getDateAndTime(product.dataActiune),
              badgeColor: "light-info",
            },
          ],
        },
      ],
    };
  });
