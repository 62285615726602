import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import DatePicker from "../../../../components/misc/DatePicker";
import {
  dateForDatabase,
  getStartFromSpecificDate,
} from "../../../../utils/dateAndTime";
import useTableCtx from "../../../../components/tables/tableContext/useTableCtx";

const startingDate = "09 April, 2024";

function BorderouHistoryDataHeader() {
  const { handleTableSearch } = useTableCtx() || {};

  const [endDate, setEndDate] = useState<Date | null>(new Date());

  useEffect(() => {
    handleTableSearch &&
      handleTableSearch("borderouCreationDate", dateForDatabase(endDate));
  }, [handleTableSearch, endDate]);

  return (
    <Box className="d-flex justify-content-end fw-bolder text-muted bg-light w-100 p-5">
      <DatePicker
        minDate={getStartFromSpecificDate(startingDate)}
        label="De la"
        textInputClassName="bg-white"
        isRequired
        withVerticalSpacer
        fullWith={false}
        gutterBottom={false}
        maxDate={new Date()}
        value={endDate}
        handleChangeDate={(value) => setEndDate(value)}
      />
    </Box>
  );
}

export default BorderouHistoryDataHeader;
