import CustomButton from "../../../../components/CustomButton";
import useTableCtx from "../../../../components/tables/tableContext/useTableCtx";
import { getDownloadPlasticBoxes } from "../../../../redux/api/expedition.api";
import { useAppDispatch } from "../../../../redux/hooks";
import Text from "../../../../styleguide/Text";

function MonitorPlasticBoxesHeader() {
  const { tablePagination, tableFilter, tableSearch } = useTableCtx() || {};

  const dispatch = useAppDispatch();

  const handleDownloadPdf = () => {
    if (!tablePagination || !tableFilter || !tableSearch) {
      return;
    }
    dispatch(
      getDownloadPlasticBoxes({
        page: tablePagination.page,
        per_page: tablePagination.rowsPerPage,
        status: tableFilter?.status,
        pctDeLivr: tableSearch?.pctDeLivr,
        ruta: tableSearch?.ruta,
        sn: tableSearch?.sn,
      }),
    );
  };

  return (
    <div className="d-flex justify-content-between align-items-center mb-6">
      <Text variant="h3" component="h3" className="card-title">
        Monitorizare cutii plastic
      </Text>
      <CustomButton variant="contained" onClick={handleDownloadPdf}>
        Descarca situatie
      </CustomButton>
    </div>
  );
}

export default MonitorPlasticBoxesHeader;
