import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { TableLoading } from "../../components/tables/components/TableLoading";
import { TableActions } from "../../components/tables/tableContext/TableContext";
import { tableLocalCategorySearch } from "../../components/tables/tableHelper";
import TableWidget from "../../components/tables/TableWidget";
import useRoutesHelper from "../../hooks/useRoutesHelper";
import { getNrcdDetails } from "../../redux/api/receipt.api";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { clearNrcdDetails } from "../../redux/reducer/receipt.reducer";
import { dateToString } from "../../utils/dateAndTime";
import objectParsers from "../../utils/objectParsers";
import NrcdTableActionButton from "./components/TableActionsButtonsComponents/NrcdTableActionButton";
import {
  nrcdTableHeader,
  getNrcdTableData,
} from "./components/tableConfigs/nrcdTableConfig";

function Nrcd() {
  const dispatch = useAppDispatch();
  const { routeParams }: any = useRoutesHelper();
  const receptionId = routeParams?.reception;
  const receptionCreatedAt = routeParams?.createdAt;

  const {
    receipt: {
      nrcdDetails: {
        data: { nrcdList, furnizor },
        isLoadingNrcdDetails,
      },
    },
  } = useAppSelector((state) => ({
    receipt: state.receipt,
  }));

  const [tableData, setTableData] = useState<any>([]);

  const receptionCreationDate =
    receptionCreatedAt && dateToString(receptionCreatedAt);
  // const nrcdCreationDate = startNrcd && dateToString(startNrcd);

  useEffect(() => {
    if (receptionId) {
      dispatch(getNrcdDetails(receptionId));
    }
  }, [dispatch, receptionId]);

  useEffect(() => {
    if (nrcdList.length > 0) {
      setTableData(nrcdList);
    }
  }, [nrcdList]);

  useEffect(() => {
    return () => {
      dispatch(clearNrcdDetails());
    };
  }, [dispatch]);

  if (isLoadingNrcdDetails) {
    return <TableLoading />;
  }

  const handleTableActions = (tableActions: TableActions) => {
    if (objectParsers.hasValue(tableActions.tableSearch)) {
      const filteredTableData = tableLocalCategorySearch(
        nrcdList,
        tableActions.tableSearch,
      );
      setTableData(filteredTableData);
      return;
    }
    setTableData(nrcdList);
  };

  const tableTitle = `NIR de la ${furnizor} din data ${receptionCreationDate}`;

  return (
    <Box>
      <TableWidget
        tableTitle={tableTitle}
        tableHeaderData={nrcdTableHeader}
        tableItemsData={getNrcdTableData(tableData)}
        borderedRow
        withTablePagination={false}
        ActionButtonsComponent={(props: any) => (
          <NrcdTableActionButton {...props} showEditButton={false} />
        )}
        handleTableActions={handleTableActions}
      />
    </Box>
  );
}

export default Nrcd;
