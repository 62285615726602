import React from "react";
import { getDuplicateProducts } from "../../../redux/api/inventory.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import TableWidget from "../../../components/tables/TableWidget";
import { TableActions } from "../../../components/tables/tableContext/TableContext";

import {
  duplicateProductsTableHeader,
  getDuplicateProductsTableData,
} from "./tableConfigs/duplicateProductsTableConfig";

function DuplicateProducts() {
  const dispatch = useAppDispatch();

  const {
    inventory: {
      duplicateProducts: {
        data: { data: duplicateProducts, total },
        isLoading,
      },
    },
  } = useAppSelector((state) => ({
    inventory: state.inventory,
  }));

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableFilter.type
    ) {
      dispatch(
        getDuplicateProducts({
          page: action.tablePagination.page,
          per_page: action.tablePagination.rowsPerPage,
          type: action.tableFilter.type,
        }),
      );
    }
  };

  return (
    <TableWidget
      tableTitle="Produse duplicate"
      tableHeaderData={duplicateProductsTableHeader}
      tableItemsData={getDuplicateProductsTableData(duplicateProducts)}
      totalItems={total}
      tableLoading={isLoading}
      handleTableActions={handleTableActions}
      withGlobalSearch={false}
      withTablePagination
    />
  );
}

export default DuplicateProducts;
