import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { clearDepositStocks } from "../../../redux/reducer/inventory.reducer";
import { getQuarantinedProductsToDestroy } from "../../../redux/api/inventory.api";
import useTableCtx from "../../../components/tables/tableContext/useTableCtx";

import {
  getQuarantinedProductsToDestroyTableData,
  quarantinedProductsToDestroyTableHeader,
} from "./tableConfigs/quarantinedProductsToDestroyConfig";

import TableWidget from "../../../components/tables/TableWidget";
import CustomButton from "../../../components/CustomButton";
import DestroyProductsModal from "./components/DestroyProductsModal";

function ProductsDestructionProcedure() {
  const dispatch = useAppDispatch();
  const {
    inventory: {
      quarantinedProductsToDestroy: {
        data: { data: produseCarantinate, total: nrTotalProduse },
        isLoading,
      },
    },
  } = useAppSelector((state) => ({
    inventory: state.inventory,
  }));

  useEffect(() => {
    dispatch(
      getQuarantinedProductsToDestroy({
        page: 1,
        perPage: 10000,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearDepositStocks());
    };
  }, [dispatch]);

  const CustomHeaderComponent = () => {
    const { handleCheckAllItems, selectedItems } = useTableCtx() || {};

    return (
      <div className="mb-5">
        <CustomButton
          className="me-2"
          onClick={() => handleCheckAllItems && handleCheckAllItems()}>
          Selecteaza toate
        </CustomButton>
        {selectedItems && selectedItems.length > 0 && (
          <DestroyProductsModal selectedProductsToDestroy={selectedItems} />
        )}
      </div>
    );
  };

  return (
    <TableWidget
      tableTitle={"Procedura distrugere produse"}
      CustomHeaderComponent={CustomHeaderComponent}
      tableHeaderData={quarantinedProductsToDestroyTableHeader}
      tableItemsData={getQuarantinedProductsToDestroyTableData(
        produseCarantinate,
      )}
      totalItems={nrTotalProduse}
      tableLoading={isLoading}
      withGlobalSearch={false}
      withGlobalFilter={false}
      withTablePagination={false}
    />
  );
}

export default ProductsDestructionProcedure;
