import { useState } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Button,
  ButtonProps,
} from "@mui/material";
import ConfirmationModal from "./misc/ConfirmationModal";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import LoadingIndicator from "./misc/LoadingIndicator";

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: "1.075rem",
    maxWidth: 500,
  },
});
interface CustomProps {
  navigate?: string;
  withDefaultAnchor?: boolean;
  tooltipTitle?: string;
  tooltipArrow?: boolean;
  tooltipPosition?: TooltipProps["placement"];
  loading?: boolean;
  loadingSize?: number;
  withConfirmationModal?: {
    modalTitle?: string;
    modalSubtitle?: string;
    modalLeftButtonText?: string;
    modalRightButtonText?: string;
    modalLeftButtonOnClick?: () => void;
    modalRightButtonOnClick?: () => void;
    modalLeftButtonColor?:
      | "error"
      | "info"
      | "inherit"
      | "primary"
      | "secondary"
      | "success";
    modalRightButtonColor?:
      | "error"
      | "info"
      | "inherit"
      | "primary"
      | "secondary"
      | "success";
    modalLeftButtonVariant?: "contained" | "outlined" | "text";
    modalRightButtonVariant?: "contained" | "outlined" | "text";
    modalSize?: "sm" | "lg" | "xl";
  };
  withDropdown?: {
    menuItems: {
      value: string | number;
      label: string | number;
      onClick: () => void;
    }[];
    anchorOrigin?: {
      vertical?: "top" | "bottom" | "center";
      horizontal?: "right" | "left" | "center";
    };
  };
}

const CustomButton = (props: ButtonProps & CustomProps) => {
  const buttonProps = {
    ...props,
  };
  delete buttonProps.withDefaultAnchor;
  delete buttonProps.tooltipTitle;
  delete buttonProps.tooltipArrow;
  delete buttonProps.tooltipPosition;
  delete buttonProps.loading;
  delete buttonProps.withConfirmationModal;
  delete buttonProps.withDropdown;

  const tooltipArrow =
    props.tooltipArrow === undefined ? true : props.tooltipArrow;

  return (
    <TooltipWrapper
      arrow={tooltipArrow}
      title={props.tooltipTitle}
      placement={props.tooltipPosition}>
      <LinkWrapper navigate={!buttonProps.disabled ? props.navigate : null}>
        <ChildrenWrapper
          buttonProps={{ ...buttonProps, disabled: !!buttonProps.disabled }}
          props={props}
        />
      </LinkWrapper>
    </TooltipWrapper>
  );
};

const ChildrenWrapper = ({
  buttonProps,
  props,
}: {
  buttonProps: any;
  props: CustomProps;
}) => {
  if (props.withConfirmationModal) {
    return <ConfirmationModalWrapper props={props} buttonProps={buttonProps} />;
  }

  if (props.withDropdown) {
    return <DropdownWrapper props={props} buttonProps={buttonProps} />;
  }

  return (
    <Button {...buttonProps} onClick={buttonProps.onClick}>
      {props.loading ? (
        <LoadingIndicator size={props.loadingSize ?? 15} color="inherit" />
      ) : (
        buttonProps.children
      )}
    </Button>
  );
};

const DropdownWrapper = ({
  buttonProps,
  props,
}: {
  buttonProps: any;
  props: CustomProps;
}) => {
  const { withDropdown } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button {...buttonProps} onClick={handleMenuOpen}>
        {props.loading ? (
          <LoadingIndicator size={props.loadingSize ?? 15} color="inherit" />
        ) : (
          buttonProps.children
        )}
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: withDropdown?.anchorOrigin?.vertical ?? "top",
          horizontal: withDropdown?.anchorOrigin?.horizontal ?? "right",
        }}
        transformOrigin={{
          vertical: withDropdown?.anchorOrigin?.vertical ?? "top",
          horizontal: withDropdown?.anchorOrigin?.horizontal ?? "right",
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}>
        {withDropdown?.menuItems.map((menuItem) => (
          <MenuItem
            key={menuItem.value}
            onClick={() => {
              handleMenuClose();
              // setTimeout is used to avoid two modals overlapping
              setTimeout(() => {
                menuItem.onClick();
              }, 400);
            }}>
            {menuItem.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const ConfirmationModalWrapper = ({
  buttonProps,
  props,
}: {
  buttonProps: any;
  props: CustomProps;
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleClose = () => {
    setShowConfirmationModal(false);
  };

  const handleButtonClick = () => {
    setShowConfirmationModal(true);
  };

  if (!props.withConfirmationModal) {
    return <></>;
  }

  return (
    <>
      <ConfirmationModal
        show={showConfirmationModal}
        handleClose={handleClose}
        title={props.withConfirmationModal.modalTitle}
        subtitle={props.withConfirmationModal.modalSubtitle}
        leftButtonText={props.withConfirmationModal.modalLeftButtonText}
        leftButtonOnClick={props.withConfirmationModal.modalLeftButtonOnClick}
        rightButtonText={props.withConfirmationModal.modalRightButtonText}
        rightButtonOnClick={props.withConfirmationModal.modalRightButtonOnClick}
        leftButtonColor={props.withConfirmationModal.modalLeftButtonColor}
        rightButtonColor={props.withConfirmationModal.modalRightButtonColor}
        leftButtonVariant={props.withConfirmationModal.modalLeftButtonVariant}
        rightButtonVariant={props.withConfirmationModal.modalRightButtonVariant}
        modalSize={props.withConfirmationModal.modalSize}
      />
      <Button {...buttonProps} onClick={handleButtonClick}>
        {props.loading ? (
          <LoadingIndicator size={props.loadingSize} color="inherit" />
        ) : (
          buttonProps.children
        )}
      </Button>
    </>
  );
};

const LinkWrapper = ({
  navigate,
  children,
}: {
  navigate?: string | null;
  children: any;
}) => {
  if (!navigate) {
    return children;
  }

  return (
    <Link to={navigate} className="text-decoration-none text-white">
      {children}
    </Link>
  );
};

const TooltipWrapper = ({
  children,
  title,
  arrow,
  placement = "top-start",
}: {
  children: any;
  title?: string | null;
  arrow?: boolean;
  placement?: TooltipProps["placement"];
}) => {
  if (!title) return children;
  return (
    <CustomWidthTooltip arrow={arrow} title={title} placement={placement}>
      <span>{children}</span>
    </CustomWidthTooltip>
  );
};

export default CustomButton;
