export const removeDuplicatesFromArray = (
  array: any[],
  keys: string[] | string,
) => {
  let objectKeys: any[] = [];
  if (typeof keys === "string") {
    objectKeys = [keys];
  } else {
    objectKeys = keys;
  }
  return array.filter(
    (v, i, a) =>
      a.findIndex((t) => objectKeys.every((k) => t[k] === v[k])) === i,
  );
};
