import { createSlice } from "@reduxjs/toolkit";
import {
  fetchCommandHistory,
  getDeliveryArrangement,
  getDeliveryInvoices,
  getListaCutiiComanda,
  getPackingBoxes,
  getPrintAWBPackingBoxes,
  getPrintCommandInvoice,
  getVerifyCommandProducts,
  postValidateAWBVerification,
  postValidateVerification,
} from "../api/avizPicking.api";
import {
  VerifyCommandProductsInterface,
  PackingBoxesInterface,
  PrintCommandInvoiceInterface,
  CommandPackageListInterface,
  DeliveryArrangementInterface,
  ValidateVerificationInterface,
  PrintAwbPackingBoxesInterface,
  ValidateAwbVerificationInterface,
  DeliveryInvoicesInterface,
  VerifyCommandProductsListInterface,
  PackingBoxesListInterface,
  CommandHistoryInterface,
} from "../interfaces/avizPicking.interface";

const initiaPickingState: {
  verifyCommand: {
    verificationZone: string;
    step: number;
  };
  verifyCommandProducts: VerifyCommandProductsInterface;
  packingBoxes: PackingBoxesInterface;
  printAwbPackingBoxes: PrintAwbPackingBoxesInterface;
  printCommandInvoice: PrintCommandInvoiceInterface;
  commandPackageList: CommandPackageListInterface;
  deliveryArrangement: DeliveryArrangementInterface;
  validateVerification: ValidateVerificationInterface;
  validateAwbVerification: ValidateAwbVerificationInterface;
  deliveryInvoices: DeliveryInvoicesInterface;
  commandHistory: CommandHistoryInterface;
} = {
  verifyCommand: {
    verificationZone: "",
    step: 0,
  },
  verifyCommandProducts: {
    data: {
      status: null,
      cumparator: null,
      pctDeLivr: null,
      userName: null,
      list: [],
      facturi: [],
      ruta: null,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  packingBoxes: {
    data: [],
    isLoading: true,
    isError: false,
    errorMessage: "",
  },
  printAwbPackingBoxes: {
    data: [],
    isLoading: true,
    isError: false,
    errorMessage: "",
  },
  printCommandInvoice: {
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  commandPackageList: {
    data: [],
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  deliveryArrangement: {
    data: {
      nrPagini: 1,
      nrTotal: 1,
      orderPicking: [],
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  validateVerification: {
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  validateAwbVerification: {
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  deliveryInvoices: {
    data: [],
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  commandHistory: {
    data: {
      total: 1,
      data: [],
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
};

const pickingReducer = createSlice({
  name: "picking",
  initialState: initiaPickingState,
  reducers: {
    setVerifyCommand(
      state,
      action: {
        payload: {
          verificationZone?: string;
          step?: number;
        };
        type: string;
      },
    ) {
      return {
        ...state,
        verifyCommand: {
          ...state.verifyCommand,
          ...action.payload,
        },
      };
    },
    setPackingBoxes(
      state,
      action: { payload: PackingBoxesListInterface[]; type: string },
    ) {
      return {
        ...state,
        packingBoxes: {
          ...state.packingBoxes,
          data: action.payload,
        },
      };
    },
    resetVerifyCommandProducts: (state) => {
      return {
        ...state,
        verifyCommandProducts: {
          ...initiaPickingState.verifyCommandProducts,
        },
      };
    },
    resetPrintAwbPackingBoxes: (state) => {
      return {
        ...state,
        printAwbPackingBoxes: {
          ...initiaPickingState.printAwbPackingBoxes,
        },
      };
    },
    setVerifyCommandProductsList: (state, action) => {
      const newList: VerifyCommandProductsListInterface[] = action.payload;
      return {
        ...state,
        verifyCommandProducts: {
          ...state.verifyCommandProducts,
          data: {
            ...state.verifyCommandProducts.data,
            list: newList,
          },
        },
      };
    },
  },
  extraReducers: (builder) => {
    // get  verify command products start
    builder.addCase(getVerifyCommandProducts.pending, (state) => {
      return {
        ...state,
        verifyCommandProducts: {
          ...initiaPickingState.verifyCommandProducts,
          isLoading: true,
        },
      };
    });
    builder.addCase(getVerifyCommandProducts.rejected, (state, action: any) => {
      return {
        ...state,
        verifyCommandProducts: {
          ...state.verifyCommandProducts,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(getVerifyCommandProducts.fulfilled, (state, action) => {
      return {
        ...state,
        verifyCommandProducts: {
          ...state.verifyCommandProducts,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    //  get verify command products end
    // get packing boxes start
    builder.addCase(getPackingBoxes.pending, (state) => {
      return {
        ...state,
        packingBoxes: {
          ...initiaPickingState.packingBoxes,
          isLoading: true,
        },
      };
    });
    builder.addCase(getPackingBoxes.rejected, (state, action: any) => {
      return {
        ...state,
        packingBoxes: {
          ...state.packingBoxes,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(getPackingBoxes.fulfilled, (state, action) => {
      return {
        ...state,
        packingBoxes: {
          ...state.packingBoxes,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // get packing boxes end
    // get verify awb packing boxes start
    builder.addCase(getPrintAWBPackingBoxes.pending, (state) => {
      return {
        ...state,
        printAwbPackingBoxes: {
          ...initiaPickingState.printAwbPackingBoxes,
          isLoading: true,
        },
      };
    });
    builder.addCase(getPrintAWBPackingBoxes.rejected, (state, action: any) => {
      return {
        ...state,
        printAwbPackingBoxes: {
          ...state.printAwbPackingBoxes,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(getPrintAWBPackingBoxes.fulfilled, (state, action) => {
      return {
        ...state,
        printAwbPackingBoxes: {
          ...state.printAwbPackingBoxes,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // get verify awb packing boxes end
    // get print command start
    builder.addCase(getPrintCommandInvoice.pending, (state) => {
      return {
        ...state,
        printCommandInvoice: {
          ...initiaPickingState.printCommandInvoice,
          isLoading: true,
        },
      };
    });
    builder.addCase(getPrintCommandInvoice.rejected, (state, action: any) => {
      return {
        ...state,
        printCommandInvoice: {
          ...state.printCommandInvoice,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(getPrintCommandInvoice.fulfilled, (state) => {
      return {
        ...state,
        printCommandInvoice: {
          ...state.printCommandInvoice,
          isLoading: false,
        },
      };
    });
    // get print command end
    // get command package list start
    builder.addCase(getListaCutiiComanda.pending, (state) => {
      return {
        ...state,
        commandPackageList: {
          ...initiaPickingState.commandPackageList,
          isLoadingCommandPackageList: true,
        },
      };
    });
    builder.addCase(getListaCutiiComanda.rejected, (state, action: any) => {
      return {
        ...state,
        commandPackageList: {
          ...state.commandPackageList,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(getListaCutiiComanda.fulfilled, (state, action) => {
      return {
        ...state,
        commandPackageList: {
          ...state.commandPackageList,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // get command package list end
    // get delivery arrangement start
    builder.addCase(getDeliveryArrangement.pending, (state) => {
      return {
        ...state,
        deliveryArrangement: {
          ...initiaPickingState.deliveryArrangement,
          isLoading: true,
        },
      };
    });
    builder.addCase(getDeliveryArrangement.rejected, (state, action: any) => {
      return {
        ...state,
        deliveryArrangement: {
          ...state.deliveryArrangement,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(getDeliveryArrangement.fulfilled, (state, action) => {
      return {
        ...state,
        deliveryArrangement: {
          ...state.deliveryArrangement,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // get delivery arrangement end

    // get post validate verification start
    builder.addCase(postValidateVerification.pending, (state) => {
      return {
        ...state,
        validateVerification: {
          isLoading: true,
          isError: false,
          errorMessage: "",
        },
      };
    });
    builder.addCase(postValidateVerification.rejected, (state, action: any) => {
      return {
        ...state,
        validateVerification: {
          isLoading: false,
          isError: true,
          errorMessage: action.payload.message,
        },
      };
    });
    builder.addCase(postValidateVerification.fulfilled, (state) => {
      return {
        ...state,
        validateVerification: {
          isError: false,
          isLoading: false,
          errorMessage: "",
        },
      };
    });
    // get post validate verification end
    // postValidateAWBVerification start
    builder.addCase(postValidateAWBVerification.pending, (state) => {
      return {
        ...state,
        validateAwbVerification: {
          isLoading: true,
          isError: false,
          errorMessage: "",
        },
      };
    });
    builder.addCase(
      postValidateAWBVerification.rejected,
      (state, action: any) => {
        return {
          ...state,
          validateAwbVerification: {
            isLoading: false,
            isError: true,
            errorMessage: action.payload.message,
          },
        };
      },
    );
    builder.addCase(postValidateAWBVerification.fulfilled, (state) => {
      return {
        ...state,
        validateAwbVerification: {
          isError: false,
          isLoading: false,
          errorMessage: "",
        },
      };
    });

    // postValidateAWBVerification end

    // getDeliveryInvoices start
    builder.addCase(getDeliveryInvoices.pending, (state) => {
      return {
        ...state,
        deliveryInvoices: {
          ...initiaPickingState.deliveryInvoices,
          isLoading: true,
        },
      };
    });
    builder.addCase(getDeliveryInvoices.rejected, (state, action: any) => {
      return {
        ...state,
        deliveryInvoices: {
          ...initiaPickingState.deliveryInvoices,
          isError: true,
          errorMessage: action.payload.message,
        },
      };
    });
    builder.addCase(getDeliveryInvoices.fulfilled, (state, action) => {
      return {
        ...state,
        deliveryInvoices: {
          ...initiaPickingState.deliveryInvoices,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // getDeliveryInvoices end
    // fetch  commandHistory start

    builder.addCase(fetchCommandHistory.pending, (state) => {
      return {
        ...state,
        commandHistory: {
          ...initiaPickingState.commandHistory,
          isLoading: true,
        },
      };
    });
    builder.addCase(fetchCommandHistory.rejected, (state, action: any) => {
      return {
        ...state,
        commandHistory: {
          ...initiaPickingState.commandHistory,
          isError: true,
          errorMessage: action.payload.message,
        },
      };
    });
    builder.addCase(fetchCommandHistory.fulfilled, (state, action) => {
      return {
        ...state,
        commandHistory: {
          ...initiaPickingState.commandHistory,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // fetch commandHistory end
  },
});

export const {
  resetVerifyCommandProducts,
  resetPrintAwbPackingBoxes,
  setVerifyCommandProductsList,
  setVerifyCommand,
  setPackingBoxes,
} = pickingReducer.actions;

export default pickingReducer.reducer;
