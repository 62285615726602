import { useAppSelector } from "../redux/hooks";

import {
  adminRoutes,
  avizPickingRoutes,
  expeditionRoutes,
  inventoryRoutes,
  pickingRoutes,
  putawayRoutes,
  receptionRoutes,
  reportsRoutes,
} from "./routesConstants";

import userRoles from "../constants/userRoles";

import { Error404 } from "../pages/Error/Error404";
import { Error500 } from "../pages/Error/Error500";
import { ErrorsPage } from "../pages/Error/ErrorPage";
import TotalReceivedProducts from "../pages/Inventory/TotalReceivedProducts";
import Reception from "../pages/Reception";
import AttachInvoice from "../pages/Reception/AttachInvoice";
import CreateReception from "../pages/Reception/CreateReception";
import EditReception from "../pages/Reception/EditReception";
import Nrcd from "../pages/Reception/Nrcd";
import EditProduct from "../pages/Reception/EditProduct";
import Quarantine from "../pages/Reception/Quarantine";
import ReceivedProducts from "../pages/Reception/ReceivedProducts";
import PreviewQuarantine from "../pages/Reception/PreviewQuarantine";
import TransferReceived from "../pages/Reception/TransferReceived";
import PutawayTasks from "../pages/Putaway/PutawayTasks";
import TransferTasks from "../pages/Reception/TransferTasks";
import PickingScheduleWorkingPoints from "../pages/Expedition/ScheduleWorkingPoints";
import PickingScheduleRoutes from "../pages/Expedition/ScheduleWorkingRoutes";
import PickingScheduleWorkingPointsOrganise from "../pages/Expedition/ScheduleWorkingPoints/ExpeditionScheduleWorkingPointsOrganise";
import PickingScheduleWorkingRoutesOrganise from "../pages/Expedition/ScheduleWorkingRoutes/ExpeditionScheduleWorkingRoutesOrganise";
import TransferReceivedOperations from "../pages/Reception/TransferReceivedOperations";
import Raports from "../pages/Reception/Raports";
import MonitorQuarantine from "../pages/Reception/MonitorQuarantine";
import Stocks from "../pages/Inventory/DepositStocks";
import VerifyCommand from "../pages/Picking/VerifyCommand";
import PrintCommandInvoice from "../pages/Picking/PrintCommandInvoice";
import ScanCommandPackage from "../pages/Picking/PrintCommandInvoice/ScanCommandPackage";
import DeliveryArrangement from "../pages/Picking/DeliveryArrangement";
import NodesRoute from "../pages/Expedition/Nodes";
import EditNodeRoutes from "../pages/Expedition/Nodes/EditNode";
import SplitInvoices from "../pages/Picking/SplitInvoices";
import Nomenclature from "../pages/Inventory/Nomenclature";
import NomenclatureEditProduct from "../pages/Inventory/Nomenclature/NomenclatureEditProduct";
import NomenclatureEditPackage from "../pages/Inventory/Nomenclature/NomenclatureEditPackage";
import NomenclatureEditLimit from "../pages/Inventory/Nomenclature/NomenclatureEditLimit";
import Users from "../pages/Admin/Users";
import CreateUser from "../pages/Admin/CreateUser";
import AdministrateUserWebRoles from "../pages/Admin/AdministrateUserWebRoles";
import EditUser from "../pages/Admin/EditUser";
import IncompleteInvoices from "../pages/Expedition/IncompleteInvoices";
import AdministrateUserMobileRoles from "../pages/Admin/AdministrateUserMobileRoles";
import ReturningTasks from "../pages/Returning/ReturningTasks";
import CreateReturn from "../pages/Returning/CreateReturningTask";
import CreateReturningForm from "../pages/Returning/CreateReturningForm";
import ReturnDifferences from "../pages/Returning/ReturnDifferences";
import ScanPlasticBoxes from "../pages/Expedition/ScanPlasticBoxes";
import MonitoringPlasticBoxes from "../pages/Expedition/MonitoringPlasticBoxes";
import PutawayRefillTasks from "../pages/Putaway/RefillTasks";
import PutawayRearrangementTasks from "../pages/Putaway/RearrangementTasks";
import BorderouHistory from "../pages/Expedition/BorderouHistory";
import VerificationZones from "../pages/Picking/VerificationZones";
import ProductsThatExpire from "../pages/Inventory/ProductsThatExpire";
import ProductsDestructionProcedure from "../pages/Inventory/ProductsDestructionProcedure";
import ProductsDestroyedRaports from "../pages/Inventory/ProductsDestroyedRaports";
import ProductHistory from "../pages/Inventory/ProductHistory";
import CommandHistory from "../pages/Picking/CommandHistory";
import AvizPickingVerifyCommand from "../pages/AvizPicking/AvizPickingVerifyCommand";
import AvizPickingPrintCommandInvoice from "../pages/AvizPicking/AvizPickingPrintCommandInvoice";
import AvizPickingDeliveryArrangement from "../pages/AvizPicking/AvizPickingDeliveryArrangement";
import AvizPickingCommandHistory from "../pages/AvizPicking/AvizPickingCommandHistory";
import AvizPickingScanCommandPackage from "../pages/AvizPicking/AvizPickingPrintCommandInvoice/AvizPickingScanCommandPackage";
import UsersActivity from "../pages/Reports/UsersActivity";
import DuplicateProducts from "../pages/Inventory/DuplicateProducts";
import NomenclatureCopyProduct from "../pages/Inventory/Nomenclature/NomenlcatureCopyProduct";

const errorRoutes = [
  {
    id: "Error",
    path: "error/*",
    element: <ErrorsPage />,
  },
  {
    id: "Error500",
    path: "/error/500",
    element: <Error500 />,
  },
  {
    id: "Error404",
    path: "*",
    element: <Error404 />,
  },
];

const reception = [
  {
    id: userRoles.CREATE_RECEPTION,
    path: `/${receptionRoutes.reception}/${receptionRoutes.createReception}`,
    element: <CreateReception />,
  },
  {
    id: userRoles.EDIT_RECEPTION,
    path: `/${receptionRoutes.reception}/${receptionRoutes.editReception}`,
    element: <EditReception />,
  },
  {
    id: userRoles.RECEPTION,
    path: `/${receptionRoutes.reception}`,
    element: <Reception />,
  },
  {
    id: userRoles.ATTACH_INVOICE,
    path: `/${receptionRoutes.reception}/${receptionRoutes.addInvoice}`,
    element: <AttachInvoice />,
  },
  {
    id: userRoles.NRCD,
    path: `/${receptionRoutes.reception}/${receptionRoutes.nrcd}`,
    element: <Nrcd />,
  },
  {
    id: userRoles.QUARANTINE,
    path: `/${receptionRoutes.reception}/${receptionRoutes.quarantine}`,
    element: <Quarantine />,
  },
  {
    id: userRoles.PREVIEW_QUARANTINE,
    path: `/${receptionRoutes.reception}/${receptionRoutes.previewQuarantine}`,
    element: <PreviewQuarantine />,
  },
  {
    id: userRoles.RECEIVED_PRODUCTS,
    path: `/${receptionRoutes.reception}/${receptionRoutes.receivedProducts}`,
    element: <ReceivedProducts />,
  },
  {
    id: userRoles.EDIT_RECEIVED_PRODUCTS,
    path: `/${receptionRoutes.reception}/${receptionRoutes.receivedProductsEdit}`,
    element: <EditProduct />,
  },
  {
    id: userRoles.TRANSFER_RECEIVED,
    path: `/${receptionRoutes.reception}/${receptionRoutes.transferReceived}`,
    element: <TransferReceived />,
  },
  {
    id: userRoles.TRANSFER_RECEIVED_SPLIT,
    path: `/${receptionRoutes.reception}/${receptionRoutes.transferReceived}/${receptionRoutes.splitOperations}`,
    element: <TransferReceivedOperations />,
  },
  {
    id: userRoles.TRANSFER_TASKS,
    path: `/${receptionRoutes.reception}/${receptionRoutes.transferTasks}`,
    element: <TransferTasks />,
  },
  {
    id: userRoles.RAPORTS,
    path: `/${receptionRoutes.reception}/${receptionRoutes.raports}`,
    element: <Raports />,
  },
  {
    id: userRoles.MONITOR_QUARANTINE,
    path: `/${receptionRoutes.reception}/${receptionRoutes.quarantineMonitor}`,
    element: <MonitorQuarantine />,
  },
  {
    id: userRoles.RETURNS,
    path: `/${receptionRoutes.returns}`,
    element: <ReturningTasks />,
  },
  {
    id: userRoles.CREATE_RETURN,
    path: `/${receptionRoutes.returns}/${receptionRoutes.createReturn}`,
    element: <CreateReturn />,
  },
  {
    id: userRoles.RETURNS,
    path: `/${receptionRoutes.returns}/${receptionRoutes.createReturnForm}`,
    element: <CreateReturningForm />,
  },
  {
    id: userRoles.RETURNS,
    path: `/${receptionRoutes.returns}/${receptionRoutes.returnDifferences}`,
    element: <ReturnDifferences />,
  },
];

const putaway = [
  {
    id: userRoles.PUTAWAY_TASKS,
    path: `/${putawayRoutes.putaway}`,
    element: <PutawayTasks />,
  },

  {
    id: userRoles.PUTAWAY_REFILL_TASKS,
    path: `/${putawayRoutes.putaway}/${putawayRoutes.putawayRefill}`,
    element: <PutawayRefillTasks />,
  },
  {
    id: userRoles.PUTAWAY_REARRANGEMENT_TASKS,
    path: `/${putawayRoutes.putaway}/${putawayRoutes.putawayRearrangement}`,
    element: <PutawayRearrangementTasks />,
  },
];

const picking = [
  {
    id: userRoles.VERIFY_COMMAND,
    path: `/${pickingRoutes.picking}/${pickingRoutes.verifyCommand}`,
    element: <VerifyCommand />,
  },
  {
    id: userRoles.PRINT_COMMAND_INVOICES,
    path: `/${pickingRoutes.picking}/${pickingRoutes.printCommandInvoice}`,
    element: <PrintCommandInvoice />,
  },
  {
    id: userRoles.PRINT_COMMAND_PACKAGES,
    path: `/${pickingRoutes.picking}/${pickingRoutes.printCommandInvoice}/:id`,
    element: <ScanCommandPackage />,
  },
  {
    id: userRoles.DELIVERY_ARRANGEMENT,
    path: `/${pickingRoutes.picking}/${pickingRoutes.deliveryArrangement}`,
    element: <DeliveryArrangement />,
  },
  {
    id: userRoles.SPLIT_INVOICES,
    path: `/${pickingRoutes.picking}/${pickingRoutes.splitInvoices}`,
    element: <SplitInvoices />,
  },
  {
    id: userRoles.VERIFICATION_ZONES,
    path: `/${pickingRoutes.picking}/${pickingRoutes.verificationZones}`,
    element: <VerificationZones />,
  },
  {
    id: userRoles.DELIVERY_ARRANGEMENT,
    path: `/${pickingRoutes.picking}/${pickingRoutes.commandHistory}`,
    element: <CommandHistory />,
  },
];

const avizPicking = [
  {
    id: userRoles.AVIZ_VERIFY_COMMAND,
    path: `/${avizPickingRoutes.avizPicking}/${avizPickingRoutes.avizVerifyCommand}`,
    element: <AvizPickingVerifyCommand />,
  },
  {
    id: userRoles.AVIZ_PRINT_COMMAND_INVOICES,
    path: `/${avizPickingRoutes.avizPicking}/${avizPickingRoutes.avizPrintCommandInvoice}`,
    element: <AvizPickingPrintCommandInvoice />,
  },
  {
    id: userRoles.AVIZ_PRINT_COMMAND_PACKAGES,
    path: `/${avizPickingRoutes.avizPicking}/${avizPickingRoutes.avizPrintCommandInvoice}/:id`,
    element: <AvizPickingScanCommandPackage />,
  },
  {
    id: userRoles.AVIZ_DELIVERY_ARRANGEMENT,
    path: `/${avizPickingRoutes.avizPicking}/${avizPickingRoutes.avizDeliveryArrangement}`,
    element: <AvizPickingDeliveryArrangement />,
  },
  {
    id: userRoles.AVIZ_DELIVERY_ARRANGEMENT,
    path: `/${avizPickingRoutes.avizPicking}/${avizPickingRoutes.avizCommandHistory}`,
    element: <AvizPickingCommandHistory />,
  },
];

const expedition = [
  {
    id: userRoles.SCHEDULE_WORKING_POINTS,
    path: `/${expeditionRoutes.scheduleWorkingPoints}`,
    element: <PickingScheduleWorkingPoints />,
  },
  {
    id: userRoles.WORKING_POINTS_ORGANIZE,
    path: `/${expeditionRoutes.scheduleWorkingPoints}/${expeditionRoutes.organize}`,
    element: <PickingScheduleWorkingPointsOrganise />,
  },
  {
    id: userRoles.SCHEDULE_WORKING_ROUTE,
    path: `/${expeditionRoutes.scheduleWorkingRoute}`,
    element: <PickingScheduleRoutes />,
  },
  {
    id: userRoles.WORKING_ROUTES_ORGANIZE,
    path: `/${expeditionRoutes.scheduleWorkingRoute}/${expeditionRoutes.organize}`,
    element: <PickingScheduleWorkingRoutesOrganise />,
  },
  {
    id: userRoles.NODES,
    path: `/${expeditionRoutes.nodes}`,
    element: <NodesRoute />,
  },
  {
    id: userRoles.EDIT_NODES_ROUTES,
    path: `/${expeditionRoutes.editNodesRoutes}`,
    element: <EditNodeRoutes />,
  },
  {
    id: userRoles.INCOMPLETE_INVOICES,
    path: `/${expeditionRoutes.incompleteInvoices}`,
    element: <IncompleteInvoices />,
  },

  {
    id: userRoles.SCAN_PLASTIC_BOXES,
    path: `/${expeditionRoutes.scanPlasticBoxes}`,
    element: <ScanPlasticBoxes />,
  },
  {
    id: userRoles.MONITORING_PLASTIC_BOXES,
    path: `/${expeditionRoutes.monitoringPlasticBoxes}`,
    element: <MonitoringPlasticBoxes />,
  },
  {
    id: userRoles.BORDEROU_HISTORY,
    path: `/${expeditionRoutes.borderouHistory}`,
    element: <BorderouHistory />,
  },
];

const inventory = [
  {
    id: userRoles.RECEIVED_PRODUCTS,
    path: `/${inventoryRoutes.inventory}/${inventoryRoutes.receivedProducts}`,
    element: <TotalReceivedProducts />,
  },
  {
    id: userRoles.STOCKS,
    path: `/${inventoryRoutes.inventory}/${inventoryRoutes.depositStocks}`,
    element: <Stocks />,
  },
  {
    id: userRoles.STOCKS,
    path: `/${inventoryRoutes.inventory}/${inventoryRoutes.depositStocks}/${inventoryRoutes.productHistory}`,
    element: <ProductHistory />,
  },
  {
    id: userRoles.NOMENCLATURE,
    path: `/${inventoryRoutes.inventory}/${inventoryRoutes.nomenclature}`,
    element: <Nomenclature />,
  },
  {
    id: userRoles.EDIT_PRODUCT_NOMENCLATURE,
    path: `/${inventoryRoutes.inventory}/${inventoryRoutes.nomenclature}/${inventoryRoutes.editProduct}`,
    element: <NomenclatureEditProduct />,
  },
  {
    id: userRoles.EDIT_PACKAGE_NOMENCLATURE,
    path: `/${inventoryRoutes.inventory}/${inventoryRoutes.nomenclature}/${inventoryRoutes.editPackage}`,
    element: <NomenclatureEditPackage />,
  },
  {
    id: userRoles.EDIT_LIMIT_NOMENCLATURE,
    path: `/${inventoryRoutes.inventory}/${inventoryRoutes.nomenclature}/${inventoryRoutes.editLimit}`,
    element: <NomenclatureEditLimit />,
  },
  {
    id: userRoles.COPY_PRODUCT_NOMENCLATURE,
    path: `/${inventoryRoutes.inventory}/${inventoryRoutes.nomenclature}/${inventoryRoutes.copyProduct}`,
    element: <NomenclatureCopyProduct />,
  },
  {
    id: userRoles.PRODUCTS_THAT_EXPIRE_SOON,
    path: `/${inventoryRoutes.inventory}/${inventoryRoutes.productsThatExpireSoon}`,
    element: <ProductsThatExpire />,
  },
  {
    id: userRoles.PRODUCTS_DESTROYED_RAPORTS,
    path: `/${inventoryRoutes.inventory}/${inventoryRoutes.productsDestroyedRaports}`,
    element: <ProductsDestroyedRaports />,
  },
  {
    id: userRoles.PRODUCTS_DESTRUCTION_PROCEDURE,
    path: `/${inventoryRoutes.inventory}/${inventoryRoutes.productsDestructionProcedure}`,
    element: <ProductsDestructionProcedure />,
  },
  {
    id: userRoles.DUPLICATE_PRODUCTS,
    path: `/${inventoryRoutes.inventory}/${inventoryRoutes.duplicateProducts}`,
    element: <DuplicateProducts />,
  },
];

const reports = [
  {
    id: userRoles.USERS_ACTIVITY,
    path: `/${reportsRoutes.reports}/${reportsRoutes.usersActivity}/`,
    element: <UsersActivity />,
  },
];

const admin = [
  {
    id: userRoles.PREVIEW_USERS,
    path: `/${adminRoutes.users}`,
    element: <Users />,
  },
  {
    id: userRoles.CREATE_USER,
    path: `/${adminRoutes.users}/${adminRoutes.createUser}`,
    element: <CreateUser />,
  },
  {
    id: userRoles.EDIT_USER,
    path: `/${adminRoutes.users}/${adminRoutes.editUser}`,
    element: <EditUser />,
  },
  {
    id: userRoles.ADMINISTRATE_USER_ROLES,
    path: `/${adminRoutes.users}/${adminRoutes.adminUserWebRoles}`,
    element: <AdministrateUserWebRoles />,
  },
  {
    id: userRoles.ADMINISTRATE_USER_MOBILE_ROLES,
    path: `/${adminRoutes.users}/${adminRoutes.adminUserMobileRoles}`,
    element: <AdministrateUserMobileRoles />,
  },
];

function useAppRoutes() {
  const {
    user: { userRoutesAccess },
  } = useAppSelector((state) => ({
    user: state.user,
  }));

  const receptionAccess = reception.filter((route) =>
    userRoutesAccess?.includes(route.id),
  );
  const putawayAccess = putaway.filter((route) =>
    userRoutesAccess?.includes(route.id),
  );
  const pickingAccess = picking.filter((route) =>
    userRoutesAccess?.includes(route.id),
  );
  const avizPickingAccess = avizPicking.filter((route) =>
    userRoutesAccess?.includes(route.id),
  );
  const expeditionAccess = expedition.filter((route) =>
    userRoutesAccess?.includes(route.id),
  );
  const inventoryAccess = inventory.filter((route) =>
    userRoutesAccess?.includes(route.id),
  );
  const reportsAccess = reports.filter((route) =>
    userRoutesAccess?.includes(route.id),
  );
  const adminAccess = admin.filter((route) =>
    userRoutesAccess?.includes(route.id),
  );

  return {
    errorRoutes,
    allRoutes: [
      ...receptionAccess,
      ...putawayAccess,
      ...pickingAccess,
      ...avizPickingAccess,
      ...expeditionAccess,
      ...inventoryAccess,
      ...reportsAccess,
      ...adminAccess,
    ],
    reception: receptionAccess,
    putaway: putawayAccess,
    picking: pickingAccess,
    avizPicking: avizPickingAccess,
    expedition: expeditionAccess,
    inventory: inventoryAccess,
    reports: reportsAccess,
    admin: adminAccess,
  };
}

export default useAppRoutes;
