import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import { TableLoading } from "../../components/tables/components/TableLoading";
import { TableActions } from "../../components/tables/tableContext/TableContext";
import { tableLocalCategorySearch } from "../../components/tables/tableHelper";
import TableWidget from "../../components/tables/TableWidget";
import useRoutesHelper from "../../hooks/useRoutesHelper";
import {
  getPreviewQuarantineDetails,
  getValidateReception,
} from "../../redux/api/receipt.api";
import { syncInvoiceCorrection } from "../../redux/api/external.api";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { clearPreviewQuarantineDetails } from "../../redux/reducer/receipt.reducer";
import { receptionRoutes } from "../../router/routesConstants";
import { dateToString } from "../../utils/dateAndTime";
import objectParsers from "../../utils/objectParsers";
import {
  getPreviewQuarantineTableData,
  previewQuarantineTableHeader,
} from "./components/tableConfigs/previewQuarantineTableConfig";

function PreviewQuarantine() {
  const dispatch = useAppDispatch();
  const { routeParams } = useRoutesHelper();
  const navigate = useNavigate();
  const receptionId = routeParams?.reception;
  const receptionCreatedAt = routeParams?.createdAt;

  const {
    receipt: {
      previewQuarantineDetails: {
        data: { quarantineList, furnizor },
        isLoadingPreviewQuarantineDetails,
      },
      postValidateReceipt: { isLoadingValidateReceipt },
    },
  } = useAppSelector((state) => ({
    receipt: state.receipt,
  }));

  const [tableData, setTableData] = useState<any>([]);

  const receptionCreationDate =
    receptionCreatedAt && dateToString(receptionCreatedAt);

  useEffect(() => {
    if (receptionId) {
      dispatch(getPreviewQuarantineDetails(receptionId));
    }
  }, [dispatch, receptionId]);

  useEffect(() => {
    if (quarantineList.length > 0) {
      setTableData(quarantineList);
    }
  }, [quarantineList]);

  useEffect(() => {
    return () => {
      dispatch(clearPreviewQuarantineDetails());
    };
  }, [dispatch]);

  if (isLoadingPreviewQuarantineDetails) {
    return <TableLoading />;
  }

  const handleTableActions = (tableActions: TableActions) => {
    if (objectParsers.hasValue(tableActions.tableSearch)) {
      const filteredTableData = tableLocalCategorySearch(
        quarantineList,
        tableActions.tableSearch,
      );
      setTableData(filteredTableData);
      return;
    }
    setTableData(quarantineList);
  };

  const handleValidateReception = () => {
    const handleSuccess = () => {
      navigate(`/${receptionRoutes.reception}?stadiu=1`);
    };
    dispatch(
      getValidateReception({ fluxId: receptionId, callback: handleSuccess }),
    );
  };

  const handleInvoiceCorrection = () => {
    dispatch(syncInvoiceCorrection(receptionId));
  };

  const CustomHeaderComponent = () => {
    return (
      <div className="mb-5">
        <CustomButton
          className="me-3"
          disabled={isLoadingValidateReceipt}
          withConfirmationModal={{
            modalTitle: "Validati receptia?",
            modalSubtitle:
              "Ați identificat și numărat toate produsele? Odată validată recepția, aceasta nu mai poate fi deblocată.",
            modalRightButtonOnClick: handleValidateReception,
          }}
          variant="contained"
          color="success">
          Valideaza receptia
        </CustomButton>
        <CustomButton
          disabled={isLoadingValidateReceipt}
          withConfirmationModal={{
            modalTitle: "Doriti sa corectati factura?",
            modalSubtitle: "Aceasta actiune poate dura cateva minute.",
            modalRightButtonText: "Da",
            modalRightButtonOnClick: handleInvoiceCorrection,
            modalRightButtonVariant: "outlined",
            modalLeftButtonText: "Nu",
          }}
          variant="contained"
          color="success">
          Corectie factura
        </CustomButton>
      </div>
    );
  };

  const tableHeader = `Produse neconforme in urma receptiei de la furnizorul ${furnizor} din data ${receptionCreationDate}`;

  return (
    <TableWidget
      tableTitle={tableHeader}
      CustomHeaderComponent={CustomHeaderComponent}
      tableHeaderData={previewQuarantineTableHeader}
      tableItemsData={getPreviewQuarantineTableData(tableData)}
      withTablePagination={false}
      borderedRow
      handleTableActions={handleTableActions}
    />
  );
}

export default PreviewQuarantine;
