import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";

import { clearSelectProductWms } from "../../../../redux/reducer/inventory.reducer";

import {
  getSelectProductWms,
  postCopyProductNomenclature,
} from "../../../../redux/api/inventory.api";

import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../../helpers/apiHelper";
import useRoutesHelper from "../../../../hooks/useRoutesHelper";

import ContentCard from "../../../../components/misc/ContentCard";
import TextInput from "../../../../components/misc/TextInput";
import Checkbox from "../../../../components/misc/Checkbox";
import CustomButton from "../../../../components/CustomButton";

interface initialValuesInterface {
  idProdus: number | string | null;
  numeProdus: string | null;
  codProdus: string | null;
  sn: string | null;

  isLiquid: boolean;

  boxWidth: number | null;
  boxLength: number | null;
  boxHeight: number | null;
  boxWeigth: number | null;

  packWidth: number | null;
  packLength: number | null;
  packHeight: number | null;
  packWeigth: number | null;
}

const initialValues: initialValuesInterface = {
  idProdus: null,
  numeProdus: null,
  codProdus: null,
  sn: null,

  isLiquid: false,

  boxWidth: null,
  boxLength: null,
  boxHeight: null,
  boxWeigth: null,

  packWidth: null,
  packLength: null,
  packHeight: null,
  packWeigth: null,
};

const productSchema = Yup.object({
  //product sn start
  sn: Yup.string().nullable().required("Campul este obligatoriu"),
  //product sn end

  // pack start
  packWidth: Yup.number()
    .nullable()
    .required("Cantitatea trebuie specificata")
    .min(1, "Doar numere pozitive mai mari decat 0"),
  packLength: Yup.number()
    .nullable()
    .required("Cantitatea trebuie specificata")
    .min(1, "Doar numere pozitive mai mari decat 0"),
  packHeight: Yup.number()
    .nullable()
    .required("Cantitatea trebuie specificata")
    .min(1, "Doar numere pozitive mai mari decat 0"),
  packWeigth: Yup.number()
    .nullable()
    .required("Cantitatea trebuie specificata")
    .min(1, "Doar numere pozitive mai mari decat 0"),
  // pack end

  // box start
  boxWidth: Yup.number()
    .nullable()
    .required("Cantitatea trebuie specificata")
    .min(1, "Doar numere pozitive mai mari decat 0"),
  boxLength: Yup.number()
    .nullable()
    .required("Cantitatea trebuie specificata")
    .min(1, "Doar numere pozitive mai mari decat 0"),
  boxHeight: Yup.number()
    .nullable()
    .required("Cantitatea trebuie specificata")
    .min(1, "Doar numere pozitive mai mari decat 0"),
  boxWeigth: Yup.number()
    .nullable()
    .required("Cantitatea trebuie specificata")
    .min(1, "Doar numere pozitive mai mari decat 0"),
  // box end
});

function NomenclatureCopyProduct() {
  const dispatch = useAppDispatch();

  const {
    inventory: {
      selectProductWms: { data: productDetailsData, isLoadingSelectProductWms },
    },
  } = useAppSelector((state) => ({
    receipt: state.receipt,
    inventory: state.inventory,
    user: state.user,
    general: state.general,
  }));

  const navigate = useNavigate();
  const { routeParams } = useRoutesHelper();

  const { idProdus } = routeParams || {};

  const [formikValues, setFormikValues] =
    useState<initialValuesInterface>(initialValues);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (idProdus) {
      dispatch(getSelectProductWms(idProdus));
    }
  }, [dispatch, idProdus]);

  useEffect(() => {
    return () => {
      dispatch(clearSelectProductWms());
    };
  }, [dispatch]);

  useEffect(() => {
    if (productDetailsData) {
      setFormikValues((prev) => ({
        ...prev,
        idProdus: productDetailsData?.idProdusErp,
        numeProdus: productDetailsData?.numeProdusErp,
        codProdus: productDetailsData?.codProdusErp,
        sn: productDetailsData?.sn ?? prev.sn,

        isLiquid: productDetailsData?.isLiquid ?? prev.isLiquid,

        packWidth: productDetailsData?.latimeA || null,
        packLength: productDetailsData?.lungimeA || null,
        packHeight: productDetailsData?.inaltimeA || null,
        packWeigth: productDetailsData?.greutateA || null,
        boxWidth: productDetailsData?.latimeB || null,
        boxLength: productDetailsData?.lungimeB || null,
        boxHeight: productDetailsData?.inaltimeB || null,
        boxWeigth: productDetailsData?.greutateB || null,
      }));
    }
  }, [productDetailsData]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSaveCopyProduct = async (value: initialValuesInterface) => {
    setIsLoading(true);

    const resultAction = await dispatch(
      postCopyProductNomenclature({
        ...value,
        type: "product",
      }),
    );

    if (
      checkEndpointStatus(resultAction, postCopyProductNomenclature) !==
      endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(resultAction, postCopyProductNomenclature) ===
      endpointStatus.fulfilled
    ) {
      navigate(-1);
    }
  };

  return (
    <ContentCard
      cardTitle="Copiere produs"
      cardHeaderClassName="text-center"
      isLoadingClassName="d-flex flex-column justify-content-center align-items-center vh-75"
      CardHeader={() => <></>}
      isLoading={isLoadingSelectProductWms}>
      <Formik
        enableReinitialize
        initialValues={formikValues}
        validationSchema={productSchema}
        onSubmit={handleSaveCopyProduct}>
        {({
          isValid,
          errors,
          touched,
          values,
          getFieldProps,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            className="form w-100"
            onSubmit={handleSubmit}
            noValidate
            id="kt_login_signin_form">
            <ContentCard>
              <TextInput
                name="numeProdus"
                label="Nume produs"
                error={errors["numeProdus"]}
                touched={touched["numeProdus"]}
                inputProps={getFieldProps("numeProdus")}
                disabled
              />
              <TextInput
                name="serialNumber"
                label="Serial Number"
                error={errors["sn"]}
                touched={touched["sn"]}
                inputProps={getFieldProps("sn")}
              />
              <div className="d-flex">
                <Checkbox
                  name="isLiquid"
                  label="Lichid"
                  checked={values.isLiquid}
                  disabled
                  onChange={(checked) => setFieldValue("isLiquid", checked)}
                />
              </div>
            </ContentCard>

            <ContentCard cardTitle="Ambalaj">
              <TextInput
                type="number"
                label="Latime (mm)"
                name="packWidth"
                error={errors["packWidth"]}
                touched={touched["packWidth"]}
                inputProps={getFieldProps("packWidth")}
              />
              <TextInput
                type="number"
                label="Lungime (mm)"
                name="packLength"
                error={errors["packLength"]}
                touched={touched["packLength"]}
                inputProps={getFieldProps("packLength")}
              />
              <TextInput
                type="number"
                label="Inaltime (mm)"
                name="packHeight"
                error={errors["packHeight"]}
                touched={touched["packHeight"]}
                inputProps={getFieldProps("packHeight")}
              />
              <TextInput
                type="number"
                label="Greutate (g)"
                name="packWeigth"
                error={errors["packWeigth"]}
                touched={touched["packWeigth"]}
                inputProps={getFieldProps("packWeigth")}
              />
            </ContentCard>

            <ContentCard cardTitle="Bax">
              <TextInput
                type="number"
                label="Latime (mm)"
                name="boxWidth"
                error={errors["boxWidth"]}
                touched={touched["boxWidth"]}
                inputProps={getFieldProps("boxWidth")}
              />
              <TextInput
                type="number"
                label="Lungime (mm)"
                name="boxLength"
                error={errors["boxLength"]}
                touched={touched["boxLength"]}
                inputProps={getFieldProps("boxLength")}
              />
              <TextInput
                type="number"
                label="Inaltime (mm)"
                name="boxHeight"
                error={errors["boxHeight"]}
                touched={touched["boxHeight"]}
                inputProps={getFieldProps("boxHeight")}
              />
              <TextInput
                type="number"
                label="Greutate (g)"
                name="boxWeigth"
                error={errors["boxWeigth"]}
                touched={touched["boxWeigth"]}
                inputProps={getFieldProps("boxWeigth")}
              />
            </ContentCard>

            <div className="d-flex justify-content-between  mt-6">
              <CustomButton variant="outlined" onClick={handleGoBack}>
                Anuleaza
              </CustomButton>
              <CustomButton
                type="submit"
                variant="contained"
                loading={isSubmitting || isLoading}
                disabled={!isValid || isSubmitting}>
                Salveaza
              </CustomButton>
            </div>
          </form>
        )}
      </Formik>
    </ContentCard>
  );
}

export default NomenclatureCopyProduct;
