import {
  TableData,
  TableHeaderCategoryConfig,
} from "../../../../../components/tables/tableContext/TableContext";
import { VerificationZonesListInterface } from "../../../../../redux/interfaces/picking.interface";

const verificationZonesTableStatus = [
  { value: false, label: "Deblocata" },
  { value: true, label: "Blocata" },
];

const getStatusLabel = (status: any) => {
  const currentStatus = verificationZonesTableStatus.find(
    (item) => item.value === status,
  );

  return currentStatus?.label;
};

export const verificationZonesTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Cod zona",
    value: "codZona",
    minW: 125,
  },
  {
    label: "Status",
    value: "status",
    minW: 125,
  },
];

export const getVerificationZonesTableData = (
  data: VerificationZonesListInterface[],
): TableData[] =>
  data.map((item) => {
    return {
      id: item.idZona,
      crudData: item,
      data: [
        {
          title: item.codZona,
        },
        {
          badges: [
            {
              badgeText: getStatusLabel(item.status),
              badgeColor: !item.status ? "success" : "danger",
              style: { minWidth: "100px" },
            },
          ],
        },
      ],
    };
  });
