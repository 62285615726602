import React from "react";

import ContentCard from "../../../../components/misc/ContentCard";
import Text from "../../../../styleguide/Text";

function ProductHeaderTable({
  codProdus,
  lot,
  dataExpirare,
  numeProdusErp,
}: {
  codProdus: string;
  lot: string;
  dataExpirare: string;
  numeProdusErp: string;
}) {
  return (
    <ContentCard>
      <table className="table align-middle gs-0 gy-4 table-row-gray-100">
        <thead>
          <tr className="fw-bolder text-muted bg-light">
            <th className="ps-3 rounded-start">
              <Text isBold>Denumire produs</Text>
            </th>
            <th>
              <Text isBold>Lot</Text>
            </th>
            <th>
              <Text isBold>Cod produs</Text>
            </th>
            <th className="ps-3 rounded-end">
              <Text isBold>Data expirare</Text>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{numeProdusErp}</td>
            <td>{lot}</td>
            <td>{codProdus}</td>
            <td>{dataExpirare}</td>
          </tr>
        </tbody>
      </table>
    </ContentCard>
  );
}

export default ProductHeaderTable;
