import axios from "axios";
import { removeUserToken } from "../redux/reducer/auth.reducer";
import { setGlobalToast } from "../redux/reducer/globalComponents.reducer";
import { errorMessageFormat } from "./errorMessageFormat";
import packageJson from "../../package.json";

export interface RejectedObject {
  status: number;
  message: string;
  ok: boolean;
  errorApi: string;
  statusText: string;
}

const isProductionEnv = process.env.NODE_ENV === "production";

export const isDevelop = packageJson.isDebugBuild ?? !isProductionEnv;

export const errorFormatHandler = (
  err: any,
  dispatch?: any,
  withToast = true,
) => {
  const errorObject: RejectedObject = {
    status: err.response?.status,
    message: errorMessageFormat(err),
    errorApi: err?.message ?? err.error?.message,
    statusText: err.response?.statusText,
    ok: err.response?.data?.ok,
  };

  if (dispatch) {
    if (err.response?.status === 401 || err.status === 401) {
      dispatch(removeUserToken());
    }
    if (withToast) {
      dispatch(
        setGlobalToast({
          open: true,
          vertical: "bottom",
          horizontal: "right",
          message: errorMessageFormat(err),
          severity: "error",
        }),
      );
    }
  }
  return errorObject;
};

export const endpointStatus = {
  fulfilled: "fulfilled",
  pending: "pending",
  rejected: "rejected",
  notFound: "notFound",
};

export const checkEndpointStatus = (
  endpointResult: any,
  endpointCheckFunction: any,
) => {
  if (endpointCheckFunction.fulfilled.match(endpointResult)) {
    return endpointStatus.fulfilled;
  } else if (endpointCheckFunction.rejected.match(endpointResult)) {
    return endpointStatus.rejected;
  } else if (endpointCheckFunction.pending.match(endpointResult)) {
    return endpointStatus.pending;
  }
  return endpointStatus.notFound;
};

type MethodType = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

const apiEnv = isDevelop
  ? process.env.REACT_APP_TEST_API_URL
  : process.env.REACT_APP_PUBLIC_API_URL;

const axiosService = axios.create({
  baseURL: isProductionEnv ? apiEnv : undefined,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

export const apiRequest = async (
  url: string,
  method: MethodType = "GET",
  transferObject?: any,
  customHeader?: object,
) => {
  const token = localStorage.getItem("token");
  const Authorization = token ? `bearer ${token}` : "";

  return await axiosService.request({
    url,
    method,
    data: method !== "GET" ? transferObject : undefined,
    params: method === "GET" ? transferObject : undefined,
    timeout: 0,
    headers: {
      Authorization,
      ...customHeader,
    },
  });
};
