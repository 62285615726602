import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { PutawayRefillTasksListInterface } from "../../../../redux/interfaces/putaway.interface";
import { dateToString, getDateAndTime } from "../../../../utils/dateAndTime";

const putawayRefillTasksStatuses = [
  { value: 4, label: "Asignat" },
  { value: 5, label: "Finalizat" },
  { value: 10, label: "Toate" },
];

const getPutawayRefillTasksStatusLabel = (status: number) => {
  const getStatus = putawayRefillTasksStatuses.find(
    (item) => item.value === status,
  );

  return getStatus ? getStatus.label : "";
};

export const putawayRefillTasksTableHeader: TableHeaderCategoryConfig[] = [
  {
    value: "Id task",
    minW: 125,
  },
  {
    label: "Nume produs",
    value: "productName",
    withSearch: true,
    minW: 125,
  },
  {
    value: "Cod produs",
    minW: 125,
  },
  {
    value: "Lot",
    minW: 125,
  },
  {
    value: "Data expirare",
    minW: 125,
  },
  {
    value: "Cantitate",
    minW: 125,
  },
  {
    label: "Status",
    value: "status",
    withFilter: putawayRefillTasksStatuses,
    minW: 125,
  },
  {
    value: "Nume utilizator",
    minW: 125,
  },
  {
    value: "Creat la data",
    minW: 125,
  },
  {
    value: "Data preluarii",
    minW: 125,
  },
  {
    value: "Data finalizarii",
    minW: 125,
  },
];

export const getPutawayRefillTasksTableData: any = (
  data: PutawayRefillTasksListInterface[],
) =>
  data?.map((item) => {
    return {
      id: item.id,
      crudData: item,
      data: [
        {
          title: item.id,
        },
        {
          title: item.numeProdusErp,
        },
        {
          title: item.codProdus,
        },
        {
          title: item.lot,
        },
        {
          badges: [
            {
              badgeText: `${dateToString(item.dataExpirare)}`,
              badgeColor: "light-danger",
            },
          ],
        },
        {
          title: item.cantitate,
        },
        {
          badges: [
            {
              badgeText: getPutawayRefillTasksStatusLabel(item.status),
              badgeColor: "light-primary",
            },
          ],
        },
        {
          title: item.userName,
        },
        {
          badges: [
            {
              badgeText: `${getDateAndTime(item.createdAt)}`,
              badgeColor: "light-info",
            },
          ],
        },
        {
          badges: [
            {
              badgeText: `${getDateAndTime(item.startedAt, "In curs")}`,
              badgeColor: "light-info",
            },
          ],
        },
        {
          badges: [
            {
              badgeText: `${getDateAndTime(item.endedAt, "In curs")}`,
              badgeColor: "light-info",
            },
          ],
        },
      ],
    };
  });
