import React from "react";
import TableWidget from "../../../components/tables/TableWidget";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchCommandHistory } from "../../../redux/api/avizPicking.api";

import { TableActions } from "../../../components/tables/tableContext/TableContext";

import {
  commandHistoryTableHeader,
  getCommandHistoryTableData,
} from "./components/tableConfigs/commandHistoryTableConfig";

function AvizPickingCommandHistory() {
  const dispatch = useAppDispatch();
  const {
    avizPicking: {
      commandHistory: {
        data: { total, data: commandHistoryData },
        isLoading,
      },
    },
  } = useAppSelector((state) => ({
    avizPicking: state.avizPicking,
  }));

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableFilter
    ) {
      dispatch(
        fetchCommandHistory({
          page: action.tablePagination.page,
          per_page: action.tablePagination.rowsPerPage,
          facturi: action.tableSearch?.facturi ?? "",
          ruta: action.tableSearch?.ruta ?? "",
          deliveryPoint: action.tableSearch?.deliveryPoint ?? "",
          commandNumber: action.tableSearch?.commandNumber ?? "",
        }),
      );
    }
  };

  return (
    <TableWidget
      tableTitle={"Istoric comanda"}
      tableHeaderData={commandHistoryTableHeader}
      tableItemsData={getCommandHistoryTableData(commandHistoryData)}
      totalItems={total}
      borderedRow
      tableLoading={isLoading}
      handleTableActions={handleTableActions}
    />
  );
}

export default AvizPickingCommandHistory;
