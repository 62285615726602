import React, { useCallback, useMemo, useRef, useState } from "react";
import { getValidBox } from "../../../../utils/qrCodeValidators";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { setVerifyCommand } from "../../../../redux/reducer/picking.reducer";
import { postValidateVerification } from "../../../../redux/api/picking.api";
import { setGlobalToast } from "../../../../redux/reducer/globalComponents.reducer";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../../helpers/apiHelper";

import withVerifySuccessModal from "../hoc/withVerifySuccessModal";

import ContentCard from "../../../../components/misc/ContentCard";
import TextInput from "../../../../components/misc/TextInput";
import FormButtons from "../../../../components/misc/FormButtons";
import CustomButton from "../../../../components/CustomButton";
import SvgIcon from "../../../../helpers/SvgIcon";

function ScanPlasticBoxes({
  handleSuccessModal,
}: {
  handleSuccessModal?: () => void;
}) {
  const productCodeInputRef = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();
  const {
    picking: {
      packingBoxes: {
        data: packingBoxesData,
        isLoading: isLoadingPackingBoxes,
      },
      verifyCommandProducts: { data: verifyCommandProductsData },
      verifyCommand: { verificationZone },
    },
  } = useAppSelector((state) => ({
    picking: state.picking,
  }));

  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const [plasticBoxes, setPlasticBoxes] = useState<string[]>([]);
  const [scannedCode, setScannedCode] = useState<string | number | null>(null);

  const plasticBoxesNumber = useMemo(
    () =>
      packingBoxesData.reduce((acc, box) => {
        if (box.tip === 3) {
          return acc + (box?.cantitate || 0);
        }
        return acc;
      }, 0),
    [packingBoxesData],
  );

  const handleFocusProductScannedInput = useCallback(() => {
    productCodeInputRef.current?.focus();
  }, []);

  const handleGoBack = () => {
    dispatch(
      setVerifyCommand({
        step: 1,
      }),
    );
  };

  const handleValidateCommand = async () => {
    if (!verificationZone) {
      return;
    }
    const commandNr = verifyCommandProductsData?.list[0]?.Op;

    const transferData = {
      cellScanned: verificationZone,
      cutii: packingBoxesData,
      commandNumber: commandNr,
      plasticBoxes,
    };

    setIsLoadingSave(true);

    const resultAction = await dispatch(
      postValidateVerification({
        params: transferData,
      }),
    );

    if (
      checkEndpointStatus(resultAction, postValidateVerification) !==
      endpointStatus.pending
    ) {
      setIsLoadingSave(false);
    }

    if (
      checkEndpointStatus(resultAction, postValidateVerification) ===
      endpointStatus.fulfilled
    ) {
      setIsLoadingSave(false);
      handleSuccessModal && handleSuccessModal();
    }
  };

  const handleScannedCode = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // @ts-ignore
    const targetValue: string = event.target.value;
    if (targetValue && event.key == "Enter") {
      const isValidBox = getValidBox(targetValue);

      const alreadyScanned = plasticBoxes.includes(targetValue);
      if (alreadyScanned) {
        dispatch(
          setGlobalToast({
            message: "Cutia este deja adaugata",
            severity: "error",
          }),
        );
      } else if (!isValidBox) {
        dispatch(
          setGlobalToast({
            message: "Codul cutiei nu este valid",
            severity: "error",
          }),
        );
      } else if (plasticBoxes.length >= plasticBoxesNumber) {
        dispatch(
          setGlobalToast({
            message: "Numarul de cutii este mai mare decat cel necesar",
            severity: "error",
          }),
        );
      } else {
        setPlasticBoxes((prev) => {
          return [...prev, targetValue];
        });
      }

      setScannedCode("");
      handleFocusProductScannedInput();
    }
  };

  return (
    <>
      <ContentCard isLoading={isLoadingPackingBoxes}>
        <TextInput
          externalInputRef={productCodeInputRef}
          label={`Scanati cutiile de plastic (${plasticBoxes.length}/${plasticBoxesNumber})`}
          id="scannInput"
          name="scannInput"
          value={scannedCode}
          onChange={setScannedCode}
          onKeyDown={handleScannedCode}
          autoFocus
        />
      </ContentCard>
      <div className="d-flex flex-wrap">
        {plasticBoxes.map((plasticBox, index) => {
          return (
            <div
              key={index}
              style={{
                display: "flex",
                flexBasis: "50%",
                flexGrow: 1,
              }}>
              <ContentCard
                className={`w-100 border ${"text-primary border-success"}`}>
                <div className="d-flex justify-content-between">
                  <h4>{plasticBox}</h4>
                  <CustomButton
                    variant="contained"
                    color="error"
                    onClick={() =>
                      setPlasticBoxes((prev) =>
                        prev.filter((box) => box !== plasticBox),
                      )
                    }>
                    <SvgIcon type="DELETE" />
                  </CustomButton>
                </div>
              </ContentCard>
            </div>
          );
        })}
      </div>
      <FormButtons
        goBackLabel="Inapoi"
        goNextLabel={isLoadingSave ? "Se printeaza facturile" : "Salveaza"}
        nextButtonDisabled={
          isLoadingSave || plasticBoxesNumber !== plasticBoxes.length
        }
        handleGoBack={handleGoBack}
        handleGoNext={handleValidateCommand}
      />
    </>
  );
}

export default withVerifySuccessModal(ScanPlasticBoxes);
