import React, { useMemo, useState } from "react";

import { useAppDispatch } from "../../../../../../redux/hooks";
import { VerifyCommandProductsListInterface } from "../../../../../../redux/interfaces/avizPicking.interface";
import { postValidateQuantityVerification } from "../../../../../../redux/api/avizPicking.api";

import TextInput from "../../../../../../components/misc/TextInput";
import ModalWrapper from "../../../../../../components/Modal/ModalWrapper";
import CustomButton from "../../../../../../components/CustomButton";
import Text from "../../../../../../styleguide/Text";
import { dateToString } from "../../../../../../utils/dateAndTime";

import NewProductForm from "./NewProductForm";
import NewProductsList from "./NewProductsList";

export interface ProductData {
  lot: string | number | null;
  data_exp: Date | null;
  cantitate: string | number | null;
}

function withProductWrongDetails<P>(WrappedComponent: React.ComponentType<P>) {
  function WrongQuantityModal(props: P) {
    const dispatch = useAppDispatch();

    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [productSelected, setProductSelected] =
      useState<VerifyCommandProductsListInterface | null>(null);

    const [commandCode, setCommandCode] = useState<string | number | null>(
      null,
    );

    const [productsAdded, setProductsAdded] = useState<ProductData[]>([]);

    const [scannedCode, setScannedCode] = useState<string | number | null>(
      null,
    );

    const addedProductsTotalQuantity = useMemo(() => {
      return productsAdded.reduce((acc, product) => {
        return acc + Number(product.cantitate);
      }, 0);
    }, [productsAdded]);

    const handleCloseModal = () => {
      setShowModal(false);
      setProductSelected(null);
      setCommandCode(null);
      setScannedCode(null);
    };

    const handleShowWronProductDetailsModal = ({
      product,
      commandCodeValue,
    }: {
      product?: VerifyCommandProductsListInterface;
      commandCodeValue?: string | number | null;
    }) => {
      if (!product || !commandCodeValue) {
        handleCloseModal();
        return;
      }
      setShowModal(true);
      setProductSelected(product);
      setCommandCode(commandCodeValue);
    };

    const handleValidateCommand = () => {
      if (!commandCode || !productSelected) {
        return;
      }
      setIsLoadingSave(true);

      dispatch(
        postValidateQuantityVerification({
          params: {
            commandCode: commandCode.toString(),
            scannedCode: scannedCode?.toString(),
            productId: productSelected.idProdusErp.toString(),
            product: productSelected,
            productsAdded: productsAdded,
            totalQuantity: addedProductsTotalQuantity,
            type: "different",
          },
          callBack: () => {
            setIsLoadingSave(false);
            handleCloseModal();
          },
        }),
      );
    };

    const disableSaveButton = !scannedCode || productsAdded.length === 0;

    const remainingQuantity = useMemo(() => {
      return (
        Number(productSelected?.cantitate || 0) - addedProductsTotalQuantity
      );
    }, [productSelected?.cantitate, addedProductsTotalQuantity]);

    const remainingQuantityAvailable = remainingQuantity > 0;

    return (
      <>
        <ModalWrapper
          title={`Produs ${productSelected?.bnume}`}
          show={showModal}
          handleClose={() => handleShowWronProductDetailsModal({})}>
          <div className="d-flex justify-content-between mb-5">
            <Text variant="h5">Lot: {productSelected?.lot}</Text>
            <Text variant="h5">
              Data expirare: {dateToString(productSelected?.data_exp)}
            </Text>
          </div>
          <div className="d-flex justify-content-between mb-5">
            <Text variant="h5">Cantitate ramasa: {remainingQuantity}</Text>
          </div>

          <TextInput
            label="Cod scanat"
            name="codScanat"
            id="codScanat"
            value={scannedCode}
            onChange={(value) => setScannedCode(value)}
            autoFocus
          />
          {remainingQuantityAvailable && scannedCode && (
            <NewProductForm
              handleNewProductAdded={(newProduct) =>
                setProductsAdded((prev) => [...prev, newProduct])
              }
              availableQuantity={remainingQuantity}
            />
          )}

          <NewProductsList
            productsAdded={productsAdded}
            setProductsAdded={setProductsAdded}
          />

          <div className="d-flex justify-content-end">
            <CustomButton
              variant="contained"
              onClick={handleValidateCommand}
              loading={isLoadingSave}
              disabled={disableSaveButton}>
              Salveaza
            </CustomButton>
          </div>
        </ModalWrapper>
        <WrappedComponent
          {...props}
          handleShowWronProductDetailsModal={handleShowWronProductDetailsModal}
        />
      </>
    );
  }
  return WrongQuantityModal;
}

export default withProductWrongDetails;
