import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  getScanPlasticBoxesTableData,
  scanPlasticBoxesHeader,
} from "./tableConfig/scanPlasticBoxesTableConfig";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { resetPlasticBoxesScanned } from "../../../redux/reducer/expedition.reducer";
import { getScannedPlasticBox } from "../../../redux/api/expedition.api";
import { setGlobalToast } from "../../../redux/reducer/globalComponents.reducer";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../helpers/apiHelper";

import FormButtons from "../../../components/misc/FormButtons";
import ContentCard from "../../../components/misc/ContentCard";
import TextInput from "../../../components/misc/TextInput";
import TableWidget from "../../../components/tables/TableWidget";
import CustomButton from "../../../components/CustomButton";

import usePermissions from "../../../router/usePermissions";
import userRoles from "../../../constants/userRoles";
import { getValidBox } from "../../../utils/qrCodeValidators";

type VerifyType = "pickup" | "delivery" | "verify";

function ScanPlasticBoxes() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAuthorized } = usePermissions();

  const productCodeInputRef = useRef<HTMLInputElement>(null);

  const {
    expedition: {
      plasticBoxesScanned: { data: plasticBoxesScannedData },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const [scanType, setScanType] = useState<VerifyType>("pickup");
  const [scannedCode, setScannedCode] = useState<string | number | null>(null);
  const [isLoadingScannedBox, setIsLoadingScannedBox] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetPlasticBoxesScanned());
    };
  }, [dispatch]);

  const handleAddNewPlasticBox = async (sn: string) => {
    setIsLoadingScannedBox(true);

    const resultAction = await dispatch(
      getScannedPlasticBox({
        sn,
        type: scanType,
      }),
    );

    if (
      checkEndpointStatus(resultAction, getScannedPlasticBox) !==
      endpointStatus.pending
    ) {
      setIsLoadingScannedBox(false);
    }
  };

  const handleScannedCode = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // @ts-ignore
    const targetValue: string = event.target.value;
    if (targetValue && event.key == "Enter") {
      const isValidBox = getValidBox(targetValue);

      if (!isValidBox) {
        dispatch(
          setGlobalToast({
            message: "Codul cutiei nu este valid",
            severity: "error",
          }),
        );
      } else {
        handleAddNewPlasticBox(targetValue);
      }

      setScannedCode("");
      productCodeInputRef.current?.focus();
    }
  };

  const handleChangeScanType = (type: VerifyType) => {
    dispatch(resetPlasticBoxesScanned());
    setScanType(type);
    productCodeInputRef.current?.focus();
  };

  return (
    <>
      <ContentCard>
        <div className="d-flex">
          <CustomButton
            variant={scanType === "pickup" ? "contained" : "outlined"}
            className="w-100"
            onClick={() => handleChangeScanType("pickup")}>
            Ridicare cutii plastic
          </CustomButton>
          <CustomButton
            variant={scanType === "delivery" ? "contained" : "outlined"}
            className="w-100"
            onClick={() => handleChangeScanType("delivery")}>
            Predare cutii plastic
          </CustomButton>
          {isAuthorized(userRoles.VERIFY_PLASTIC_BOXES) && (
            <CustomButton
              variant={scanType === "verify" ? "contained" : "outlined"}
              className="w-100"
              onClick={() => handleChangeScanType("verify")}>
              Verificare cutii plastic
            </CustomButton>
          )}
        </div>
      </ContentCard>

      <ContentCard>
        <ContentCard isLoading={isLoadingScannedBox}>
          <TextInput
            label={"Scanati cutiile de plastic"}
            id="scannInput"
            name="scannInput"
            value={scannedCode}
            onChange={setScannedCode}
            onKeyDown={handleScannedCode}
            autoFocus
          />
        </ContentCard>
        <TableWidget
          tableTitle={`Cutii de plastic scanate ${plasticBoxesScannedData.length}`}
          tableHeaderData={scanPlasticBoxesHeader}
          tableItemsData={getScanPlasticBoxesTableData(plasticBoxesScannedData)}
          showSearchForCategories={false}
          withGlobalSearch={false}
          withGlobalFilter={false}
          withTablePagination={false}
        />
        <FormButtons goBackLabel="Inapoi" handleGoBack={() => navigate(-1)} />
      </ContentCard>
    </>
  );
}

export default ScanPlasticBoxes;
