import { createSlice } from "@reduxjs/toolkit";
import {
  getDepositStocks,
  getDuplicateProducts,
  getProductHistory,
  getProductsDestroyedRaports,
  getProductsThatExpire,
  getQuarantinedProductsToDestroy,
  getReceivedProducts,
  getSelectProductWms,
  postGetProducts,
} from "../api/inventory.api";
import {
  DuplicateProductsDataInterface,
  InventoryDepositStocksDataInterface,
  InventoryProductsDataInterface,
  InventoryProductsRaportsDestroyedDataInterface,
  InventoryProductsThatExpireDataInterface,
  InventoryQuarantinedProductsToDestroyDataInterface,
  InventoryReceivedProductDataInterface,
  ProductHistoryDataInterface,
  SelectProductWmsInterface,
} from "../interfaces/inventory.interface";

const initialInventoryState: {
  receivedProducts: InventoryReceivedProductDataInterface;
  depositStocks: InventoryDepositStocksDataInterface;
  products: InventoryProductsDataInterface;
  selectProductWms: SelectProductWmsInterface;
  productsThatExpire: InventoryProductsThatExpireDataInterface;
  quarantinedProductsToDestroy: InventoryQuarantinedProductsToDestroyDataInterface;
  productsDestroyedRaports: InventoryProductsRaportsDestroyedDataInterface;
  productHistory: ProductHistoryDataInterface;
  duplicateProducts: DuplicateProductsDataInterface;
} = {
  receivedProducts: {
    data: {
      nrPagini: 1,
      nrTotalProduse: 1,
      produse: [],
    },
    isLoadingReceivedProducts: false,
    isErrorReceivedProducts: false,
    errorMessageReceivedProducts: "",
  },
  depositStocks: {
    data: {
      nrPagini: 1,
      nrTotalStoc: 1,
      stocuri: [],
    },
    isLoadingDepositStocks: false,
    isErrorDepositStocks: false,
    errorMessageDepositStocks: "",
  },
  products: {
    data: {
      nrTotal: 1,
      produseWms: [],
    },
    isLoadingProducts: false,
    isErrorProducts: false,
    errorMessageProducts: "",
  },
  selectProductWms: {
    data: null,
    isLoadingSelectProductWms: false,
    isErrorSelecProductWms: false,
    errorMessageSelectProductWms: "",
  },
  productsThatExpire: {
    data: {
      total: 1,
      current_page: 1,
      data: [],
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  quarantinedProductsToDestroy: {
    data: {
      total: 1,
      current_page: 1,
      data: [],
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  productsDestroyedRaports: {
    data: {
      total: 1,
      current_page: 1,
      data: [],
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  productHistory: {
    data: {
      total: 1,
      current_page: 1,
      data: [],
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  duplicateProducts: {
    data: {
      total: 1,
      current_page: 1,
      data: [],
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
};

const inventoryReducer = createSlice({
  name: "inventory",
  initialState: initialInventoryState,
  reducers: {
    clearDepositStocks: (state) => {
      return {
        ...state,
        depositStocks: {
          ...initialInventoryState.depositStocks,
        },
      };
    },
    clearSelectProductWms: (state) => {
      return {
        ...state,
        selectProductWms: {
          ...initialInventoryState.selectProductWms,
        },
      };
    },
    setProductsBlockedStatus: (state, action) => {
      const newProducts = state.products.data.produseWms.map((product) => {
        if (product.idProdus === action.payload) {
          return {
            ...product,
            blocat: product.blocat ? 0 : 1,
          };
        }
        return product;
      });

      return {
        ...state,
        products: {
          ...state.products,
          data: {
            ...state.products.data,
            produseWms: newProducts,
          },
        },
      };
    },
  },
  extraReducers: (builder) => {
    // start  received products
    builder.addCase(getReceivedProducts.pending, (state) => {
      return {
        ...state,
        receivedProducts: {
          ...initialInventoryState.receivedProducts,
          isLoadingReceivedProducts: true,
        },
      };
    });
    builder.addCase(getReceivedProducts.rejected, (state, action: any) => {
      return {
        ...state,
        receivedProducts: {
          ...state.receivedProducts,
          isLoadingReceivedProducts: false,
          errorMessageReceivedProducts: action.payload.message,
          isErrorReceivedProducts: true,
        },
      };
    });
    builder.addCase(getReceivedProducts.fulfilled, (state, action) => {
      return {
        ...state,
        receivedProducts: {
          ...state.receivedProducts,
          isLoadingReceivedProducts: false,
          data: action.payload,
        },
      };
    });
    // end received products
    // start deposit stocks
    builder.addCase(getDepositStocks.pending, (state) => {
      return {
        ...state,
        depositStocks: {
          ...initialInventoryState.depositStocks,
          isLoadingDepositStocks: true,
        },
      };
    });
    builder.addCase(getDepositStocks.rejected, (state, action: any) => {
      return {
        ...state,
        depositStocks: {
          ...state.depositStocks,
          isLoadingDepositStocks: false,
          errorMessageDepositStocks: action.payload.message,
          isErrorDepositStocks: true,
        },
      };
    });
    builder.addCase(getDepositStocks.fulfilled, (state, action) => {
      return {
        ...state,
        depositStocks: {
          ...state.depositStocks,
          isLoadingDepositStocks: false,
          data: action.payload,
        },
      };
    });
    // end deposit stocks
    // start get products
    builder.addCase(postGetProducts.pending, (state) => {
      return {
        ...state,
        products: {
          ...initialInventoryState.products,
          isLoadingProducts: true,
        },
      };
    });
    builder.addCase(postGetProducts.rejected, (state, action: any) => {
      return {
        ...state,
        products: {
          ...state.products,
          isLoadingProducts: false,
          errorMessageProducts: action.payload.message,
          isErrorProducts: true,
        },
      };
    });
    builder.addCase(postGetProducts.fulfilled, (state, action) => {
      return {
        ...state,
        products: {
          ...state.products,
          isLoadingProducts: false,
          data: action.payload,
        },
      };
    });
    // end get products
    // start select product wms
    builder.addCase(getSelectProductWms.pending, (state) => {
      return {
        ...state,
        selectProductWms: {
          ...initialInventoryState.selectProductWms,
          isLoadingSelectProductWms: true,
        },
      };
    });
    builder.addCase(getSelectProductWms.rejected, (state, action: any) => {
      return {
        ...state,
        selectProductWms: {
          ...state.selectProductWms,
          isLoadingSelectProductWms: false,
          errorMessageSelectProductWms: action.payload.message,
          isErrorSelecProductWms: true,
        },
      };
    });
    builder.addCase(getSelectProductWms.fulfilled, (state, action) => {
      return {
        ...state,
        selectProductWms: {
          ...state.selectProductWms,
          isLoadingSelectProductWms: false,
          data: action.payload,
        },
      };
    });
    // end select product wms

    // start get products that expire
    builder.addCase(getProductsThatExpire.pending, (state) => {
      return {
        ...state,
        productsThatExpire: {
          ...initialInventoryState.productsThatExpire,
          isLoading: true,
        },
      };
    });
    builder.addCase(getProductsThatExpire.rejected, (state, action: any) => {
      return {
        ...state,
        productsThatExpire: {
          ...state.productsThatExpire,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(getProductsThatExpire.fulfilled, (state, action) => {
      return {
        ...state,
        productsThatExpire: {
          ...state.productsThatExpire,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // end get products that expire

    // start get quarantined products to destroy
    builder.addCase(getQuarantinedProductsToDestroy.pending, (state) => {
      return {
        ...state,
        quarantinedProductsToDestroy: {
          ...initialInventoryState.quarantinedProductsToDestroy,
          isLoading: true,
        },
      };
    });
    builder.addCase(
      getQuarantinedProductsToDestroy.rejected,
      (state, action: any) => {
        return {
          ...state,
          quarantinedProductsToDestroy: {
            ...state.quarantinedProductsToDestroy,
            isLoading: false,
            errorMessage: action.payload.message,
            isError: true,
          },
        };
      },
    );
    builder.addCase(
      getQuarantinedProductsToDestroy.fulfilled,
      (state, action) => {
        return {
          ...state,
          quarantinedProductsToDestroy: {
            ...state.quarantinedProductsToDestroy,
            isLoading: false,
            data: action.payload,
          },
        };
      },
    );
    // end get quarantined products to destroy

    // start get products destroyed raports
    builder.addCase(getProductsDestroyedRaports.pending, (state) => {
      return {
        ...state,
        productsDestroyedRaports: {
          ...initialInventoryState.productsDestroyedRaports,
          isLoading: true,
        },
      };
    });
    builder.addCase(
      getProductsDestroyedRaports.rejected,
      (state, action: any) => {
        return {
          ...state,
          productsDestroyedRaports: {
            ...state.productsDestroyedRaports,
            isLoading: false,
            errorMessage: action.payload.message,
            isError: true,
          },
        };
      },
    );
    builder.addCase(getProductsDestroyedRaports.fulfilled, (state, action) => {
      return {
        ...state,
        productsDestroyedRaports: {
          ...state.productsDestroyedRaports,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // end get products destroyed raports

    // start product history
    builder.addCase(getProductHistory.pending, (state) => {
      return {
        ...state,
        productHistory: {
          ...initialInventoryState.productHistory,
          isLoading: true,
        },
      };
    });
    builder.addCase(getProductHistory.rejected, (state, action: any) => {
      return {
        ...state,
        productHistory: {
          ...state.productHistory,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(getProductHistory.fulfilled, (state, action) => {
      return {
        ...state,
        productHistory: {
          ...state.productHistory,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // end product history

    // start duplicate products
    builder.addCase(getDuplicateProducts.pending, (state) => {
      return {
        ...state,
        duplicateProducts: {
          ...initialInventoryState.duplicateProducts,
          isLoading: true,
        },
      };
    });
    builder.addCase(getDuplicateProducts.rejected, (state, action: any) => {
      return {
        ...state,
        duplicateProducts: {
          ...state.duplicateProducts,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(getDuplicateProducts.fulfilled, (state, action) => {
      return {
        ...state,
        duplicateProducts: {
          ...state.duplicateProducts,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // end duplicate products
  },
});

export const {
  clearDepositStocks,
  clearSelectProductWms,
  setProductsBlockedStatus,
} = inventoryReducer.actions;

export default inventoryReducer.reducer;
