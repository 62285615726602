const hasKeys = (object?: object) => {
  if (object) {
    return !!Object.keys(object).length;
  }
  return false;
};

const hasValue = (object?: object, checkForObjectValue = "") => {
  if (object) {
    return !!Object.values(object).filter(
      (value) => value !== checkForObjectValue,
    ).length;
  }
  return false;
};

const getKeys = (object?: object) => {
  if (object) {
    return Object.keys(object);
  }
  return [];
};

const getValues = (object?: object) => {
  if (object) {
    return Object.values(object);
  }
  return [];
};

const getEntries = (object?: object) => {
  if (object) {
    return Object.entries(object);
  }
  return [];
};

const transformObjectToArray = (object?: object) => {
  if (object) {
    return Object.entries(object).map(([key, value]) => ({ key, value }));
  }
  return [];
};

function objectValuesToLowerCase<T extends string>(
  obj: Record<string, T>,
): Record<string, T> {
  const newObj: Record<string, T> = {};
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      newObj[key] = obj[key].toLowerCase() as T;
    }
  }
  return newObj;
}

export default {
  hasKeys,
  hasValue,
  getKeys,
  getValues,
  getEntries,
  transformObjectToArray,
  objectValuesToLowerCase,
};
