import { useAppDispatch } from "../../../../../redux/hooks";
import CustomButton from "../../../../../components/CustomButton";
import { getSyncronizeInvoices } from "../../../../../redux/api/external.api";

function DeliveryArrangementActionButton(props: any) {
  const dispatch = useAppDispatch();

  const handleSyncronizeInvoices = () => {
    dispatch(
      getSyncronizeInvoices({
        op: props.crudData.Op,
      }),
    );
  };

  return (
    <div>
      <CustomButton
        variant="contained"
        className="mt-1 me-1"
        withConfirmationModal={{
          modalLeftButtonVariant: "outlined",
          modalTitle: `Doriti sa resincronizati facturile din comanda nr: ${props.crudData.id}?`,
          modalRightButtonOnClick: handleSyncronizeInvoices,
        }}>
        Resincronizeaza
      </CustomButton>
    </div>
  );
}

export default DeliveryArrangementActionButton;
