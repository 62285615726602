import { useCallback, useEffect, useState } from "react";
import useTableCtx from "../tableContext/useTableCtx";
import {
  TableActions,
  TableData,
  TableFilterDataItem,
  TableHeaderCategoryConfig,
} from "../tableContext/TableContext";
import useRoutesHelper from "../../../hooks/useRoutesHelper";

function useTableConfig({
  handleTableActions,
  globalFilter,
  tableHeaderData,
  tableItemsData,
}: {
  handleTableActions?: (value: TableActions) => void;
  tableHeaderData: TableHeaderCategoryConfig[];
  tableItemsData: TableData[];
  globalFilter?: TableFilterDataItem[];
}) {
  const { routeParams, handleSetQuerryParams } = useRoutesHelper();

  const [initialRender, setInitialRender] = useState(true);

  const tableContext = useTableCtx()!;
  const {
    globalFilterOptions,
    tableGlobalSearch,
    selectedItems,
    tablePagination,
    tableSearch,
    tableFilter,
    debouncedTableContextValues,
    setGlobalFilterOptions,
    setTableFilter,
    setTableSearch,
    setTableData,
    setTableHeader,
    handleSetTablePagination,
  } = tableContext;

  //   we set the table initial data
  useEffect(() => {
    tableItemsData && setTableData(tableItemsData);
    tableHeaderData && setTableHeader(tableHeaderData);
    globalFilter && setGlobalFilterOptions(globalFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter, tableHeaderData, tableItemsData]);

  //   we send the table context values to the parent component
  useEffect(() => {
    const getTableActions = () => {
      if (handleTableActions && debouncedTableContextValues) {
        handleTableActions({
          ...debouncedTableContextValues,
          globalFilterOptions,
          tableGlobalSearch,
          selectedItems,
          tableContext,
        });
      }
    };
    getTableActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedTableContextValues,
    globalFilterOptions,
    tableGlobalSearch,
    selectedItems,
  ]);

  //   we handle the route params and update the table context
  const handleTableRouteParams = useCallback(
    (pageParams: any) => {
      if (pageParams?.page || pageParams?.rowsPerPage) {
        handleSetTablePagination((prev) => ({
          ...prev,
          page: pageParams.page ? parseInt(pageParams.page) : prev.page,
          rowsPerPage: pageParams.rowsPerPage
            ? parseInt(pageParams.rowsPerPage)
            : prev.rowsPerPage,
        }));
      }
      setTableFilter((prev) => ({ ...prev, ...pageParams }));
      setTableSearch((prev) => ({ ...prev, ...pageParams }));
    },
    [handleSetTablePagination, setTableFilter, setTableSearch],
  );

  // on the initial reander, we wan't to check route params and complete the table context
  useEffect(() => {
    if (initialRender) {
      if (routeParams.page) {
        handleTableRouteParams(routeParams);
        setInitialRender(false);
      }
      return;
    }
  }, [handleTableRouteParams, initialRender, routeParams]);

  // we update the route params with the table context values
  useEffect(() => {
    if (tablePagination) {
      handleSetQuerryParams({
        ...tableSearch,
        ...tableFilter,
        ...tablePagination,
      });
    }
  }, [handleSetQuerryParams, tablePagination, tableFilter, tableSearch]);
}

export default useTableConfig;
