import { memo, useState } from "react";
import { TableItemId } from "../../../../components/tables/TableWidget";
import { inventoryRoutes } from "../../../../router/routesConstants";
import CustomButton from "../../../../components/CustomButton";

import { KTSVG } from "../../../../helpers/KTSVG";
import { TableItemType } from "../../../../components/tables/tableContext/TableContext";
import { useAppDispatch } from "../../../../redux/hooks";
import { postDeactivateProductWms } from "../../../../redux/api/inventory.api";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../../helpers/apiHelper";
import { setProductsBlockedStatus } from "../../../../redux/reducer/inventory.reducer";
import SvgIcon, { SvgIconType } from "../../../../helpers/SvgIcon";
import userRoles from "../../../../constants/userRoles";
import usePermissions from "../../../../router/usePermissions";

function NomenclatureTableActionButtons({
  itemId,
  crudData,
}: {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData?: any;
  tableContext?: any;
}) {
  const dispatch = useAppDispatch();
  const { isAuthorized } = usePermissions();

  const [isLoading, setIsLoading] = useState(false);

  const isBlocked = crudData?.blocat;

  const handleGoToEditProduct = () => {
    return `/${inventoryRoutes.inventory}/${inventoryRoutes.nomenclature}/${inventoryRoutes.editProduct}?idProdus=${itemId}`;
  };

  const handleGoToEditPackage = () => {
    return `/${inventoryRoutes.inventory}/${inventoryRoutes.nomenclature}/${inventoryRoutes.editPackage}?idProdus=${itemId}`;
  };

  const handleGoToEditLimit = () => {
    return `/${inventoryRoutes.inventory}/${inventoryRoutes.nomenclature}/${inventoryRoutes.editLimit}?idProdus=${itemId}`;
  };

  const handleGoToCopyProduct = () => {
    return `/${inventoryRoutes.inventory}/${inventoryRoutes.nomenclature}/${inventoryRoutes.copyProduct}?idProdus=${itemId}`;
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const resultAction = await dispatch(
      postDeactivateProductWms({
        id: itemId,
      }),
    );

    if (
      checkEndpointStatus(resultAction, postDeactivateProductWms) !==
      endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(resultAction, postDeactivateProductWms) ===
      endpointStatus.fulfilled
    ) {
      dispatch(setProductsBlockedStatus(itemId));
    }
  };

  const deactivateButtonProperties: {
    tooltipTitle: string;
    modalTitle: string;
    modalSubtitle: string;
    svgType: SvgIconType;
  } = {
    tooltipTitle: `${isBlocked ? "Activare" : "Dezactivare"} produs`,
    modalTitle: isBlocked ? "Activare produs" : "Dezactivare produs",
    modalSubtitle: `Doriti sa ${
      isBlocked ? "activati" : "dezactivati"
    } produsul ${crudData.numeProdusErp}`,
    svgType: isBlocked ? "LOCK_OPEN" : "LOCK_CLOSE",
  };

  return (
    <div className="text-start" style={{ width: 250 }}>
      <CustomButton
        variant="contained"
        tooltipTitle="Editare produs"
        className="mx-1 mb-2"
        navigate={handleGoToEditProduct()}>
        <KTSVG
          path="/media/svg/storage/drugs.svg"
          className="svg-icon-4"
          fill="white"
        />
      </CustomButton>
      <CustomButton
        variant="contained"
        tooltipTitle="Editare ambalaj"
        className="mx-1 mb-2"
        navigate={handleGoToEditPackage()}>
        <KTSVG
          path="/media/svg/storage/package.svg"
          className="svg-icon-4"
          fill="white"
        />
      </CustomButton>
      <CustomButton
        variant="contained"
        tooltipTitle="Editare limita"
        className="mx-1 mb-2"
        navigate={handleGoToEditLimit()}>
        <KTSVG
          path="/media/svg/storage/limits.svg"
          className="svg-icon-4"
          fill="white"
        />
      </CustomButton>
      <CustomButton
        variant="contained"
        tooltipTitle="Copiere produs"
        className="mx-1 mb-2"
        navigate={handleGoToCopyProduct()}>
        <KTSVG
          path="/media/svg/storage/copy.svg"
          className="svg-icon-4"
          fill="white"
        />
      </CustomButton>
      {isAuthorized(userRoles.DEACTIVATE_PRODUCT_NOMENCLATURE) && (
        <CustomButton
          loading={isLoading}
          tooltipTitle={deactivateButtonProperties.tooltipTitle}
          withConfirmationModal={{
            modalTitle: deactivateButtonProperties.modalTitle,
            modalSubtitle: deactivateButtonProperties.modalSubtitle,
            modalLeftButtonVariant: "outlined",
            modalRightButtonOnClick: handleSubmit,
          }}
          variant="contained"
          color={isBlocked ? "success" : "error"}
          className="mx-1 mb-2">
          <SvgIcon
            className="mx-1"
            type={deactivateButtonProperties.svgType}
            fill="white"
          />
        </CustomButton>
      )}
    </div>
  );
}

export default memo(NomenclatureTableActionButtons);
