import { Box } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TableLoading } from "../../components/tables/components/TableLoading";
import { TableActions } from "../../components/tables/tableContext/TableContext";
import { tableLocalCategorySearch } from "../../components/tables/tableHelper";
import TableWidget from "../../components/tables/TableWidget";
import useRoutesHelper from "../../hooks/useRoutesHelper";
import { getQuarantineDetails } from "../../redux/api/receipt.api";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { clearQuarantineDetails } from "../../redux/reducer/receipt.reducer";
import { dateToString } from "../../utils/dateAndTime";
import objectParsers from "../../utils/objectParsers";
import QuarantineTableActionButton from "./components/TableActionsButtonsComponents/QuarantineTableActionButton";
import {
  getQuarantineTableData,
  quarantineTableHeader,
} from "./components/tableConfigs/quarantineTableConfig";
import { QuarantineListInterface } from "../../redux/interfaces/receipt.interface";

interface TableDataInterface extends QuarantineListInterface {
  lot: string;
}

function Quarantine() {
  const dispatch = useAppDispatch();
  const { routeParams } = useRoutesHelper();
  const receptionId = routeParams?.reception;
  const receptionCreatedAt = routeParams?.createdAt;

  const {
    receipt: {
      quarantineDetails: {
        data: { quarantineList, furnizor, startQuarantine },
        isLoadingQuarantineDetails,
      },
    },
  } = useAppSelector((state) => ({
    receipt: state.receipt,
  }));

  const formatQuarantineList = useMemo(
    () =>
      quarantineList.map((item) => {
        const lotDepo = item?.lotDepo ?? "";
        const lotWms = item?.lotWms ?? "";
        return {
          ...item,
          lot: `${lotDepo}-${lotWms}`,
        };
      }),
    [quarantineList],
  );

  const [tableData, setTableData] = useState<TableDataInterface[]>([]);

  const receptionCreationDate =
    receptionCreatedAt && dateToString(receptionCreatedAt);

  const quarantineCreationDate =
    startQuarantine && dateToString(startQuarantine);

  useEffect(() => {
    if (receptionId) {
      dispatch(getQuarantineDetails(receptionId));
    }
  }, [dispatch, receptionId]);

  useEffect(() => {
    if (formatQuarantineList.length > 0) {
      setTableData(formatQuarantineList);
    }
  }, [formatQuarantineList]);

  useEffect(() => {
    return () => {
      dispatch(clearQuarantineDetails());
    };
  }, [dispatch]);

  const getSortedTableDataByPriority = useCallback(() => {
    const sortedTableData = tableData?.sort((a, b) => {
      const aLot = a.lot.replace("-", "");
      const bLot = b.lot.replace("-", "");

      if (a.codProdusDepo === b.codProdusDepo) {
        return aLot.localeCompare(bLot);
      }
      return a.codProdusDepo.localeCompare(b.codProdusDepo);
    });
    return sortedTableData;
  }, [tableData]);

  if (isLoadingQuarantineDetails) {
    return <TableLoading />;
  }

  const handleTableActions = (tableActions: TableActions) => {
    if (objectParsers.hasValue(tableActions.tableSearch)) {
      const filteredTableData = tableLocalCategorySearch(
        formatQuarantineList,
        tableActions.tableSearch,
      );
      setTableData(filteredTableData);
      return;
    }
    setTableData(formatQuarantineList);
  };

  const tableHeader = `Produse neconforme in urma receptiei de la furnizorul ${furnizor} din data ${receptionCreationDate} intrate in carantina la data de ${quarantineCreationDate}`;

  return (
    <Box>
      <TableWidget
        tableTitle={tableHeader}
        tableHeaderData={quarantineTableHeader}
        tableItemsData={getQuarantineTableData(getSortedTableDataByPriority())}
        withTablePagination={false}
        borderedRow
        ActionButtonsComponent={(props: any) => (
          <QuarantineTableActionButton {...props} />
        )}
        handleTableActions={handleTableActions}
      />
    </Box>
  );
}

export default Quarantine;
