import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import DatePicker from "../../../../components/misc/DatePicker";
import {
  dateForDatabase,
  getDateWithoutNthMonths,
} from "../../../../utils/dateAndTime";
import useTableCtx from "../../../../components/tables/tableContext/useTableCtx";

function TotalReceivedProductsHeader() {
  const { handleTableSearch } = useTableCtx() || {};

  const [startDate, setStartDate] = useState<Date | null>(
    getDateWithoutNthMonths(2),
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  useEffect(() => {
    handleTableSearch &&
      handleTableSearch("startDate", dateForDatabase(startDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  useEffect(() => {
    handleTableSearch && handleTableSearch("endDate", dateForDatabase(endDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate]);

  return (
    <Box className="d-flex justify-content-between fw-bolder text-muted bg-light w-100 p-5">
      <DatePicker
        label="De la"
        textInputClassName="bg-white"
        isRequired
        withVerticalSpacer
        fullWith={false}
        gutterBottom={false}
        maxDate={new Date()}
        value={startDate}
        handleChangeDate={(value) => setStartDate(value)}
      />
      <DatePicker
        label="Pana la"
        textInputClassName="bg-white"
        isRequired
        withVerticalSpacer
        fullWith={false}
        gutterBottom={false}
        maxDate={new Date()}
        value={endDate}
        handleChangeDate={(value) => setEndDate(value)}
      />
    </Box>
  );
}

export default TotalReceivedProductsHeader;
