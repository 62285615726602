const parseDrugCode = (drugCode: string) => {
  switch (drugCode) {
    case "SL":
    case "SL 2":
      return "Supliment alimentar";
    case "DM":
    case "DM 2":
      return "Dispozitiv medical";
    case "SEPA":
      return "Separanda";
    case "COSM":
    case "COSM 2":
      return "Cosmetice";
    case "FRIG":
      return "Frig";
    case "BIO":
    case "BIO 2":
      return "Biocide";
    case "OTC":
    case "OTC 2":
    case "MED":
    case "MED2":
    case "CASH":
    case "SSB":
      return "Medicament";
    case "PF":
    case "PARA 2":
      return "Parafarmaceutice";
    default:
      return "Cod necunoscut"; // Default case for handling unexpected codes
  }
};

export { parseDrugCode };
