import {
  TableData,
  TableHeaderCategoryConfig,
} from "../../../../../components/tables/tableContext/TableContext";
import { CommandHistoryListInterface } from "../../../../../redux/interfaces/picking.interface";
import { getDateAndTime } from "../../../../../utils/dateAndTime";

const commandHistoryTableStatus = [
  { value: 0, label: "Creata" },
  { value: 1, label: "Active pentru colectare" },
  { value: 5, label: "In lucru" },
  { value: 2, label: "Depusa pt verificare" },
  { value: 8, label: "In curs de verificare" },
  { value: 3, label: "Verificata" },
  { value: 9, label: "In expeditie" },
  { value: 6, label: "Anulata" },
];

const getStatusLabel = (status: any) => {
  const currentStatus = commandHistoryTableStatus.find(
    (item) => item.value === parseInt(status),
  );

  return currentStatus?.label;
};

export const commandHistoryTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Numar comanda",
    value: "commandNumber",
    minW: 125,
    withSearch: true,
  },
  {
    label: "Status",
    value: "status",
    minW: 125,
  },
  {
    label: "Facturi",
    value: "facturi",
    minW: 125,
    withSearch: true,
  },

  {
    label: "Ruta",
    value: "ruta",
    minW: 125,
  },
  {
    label: "Punct de livrare",
    value: "deliveryPoint",
    minW: 125,
  },
  {
    label: "Cod zona",
    value: "codZona",
    minW: 125,
  },
  {
    label: "Nume utilizator",
    value: "username",
    minW: 125,
  },
  {
    label: "Data actiune",
    value: "dataActiune",
    minW: 125,
  },
];

export const getCommandHistoryTableData = (
  data: CommandHistoryListInterface[],
): TableData[] =>
  data.map((item) => {
    return {
      id: item.id,
      crudData: item,
      data: [
        {
          title: item.op,
        },
        {
          badges: [
            {
              badgeText: getStatusLabel(item.status),
            },
          ],
        },
        {
          title: item.act,
        },
        {
          title: item.ruta,
        },
        {
          title: item.pctDeLivr,
        },
        {
          title: item.codZona ?? "-",
        },
        {
          title: item.userName,
          titleStyle: { color: "#8335FF" },
        },
        {
          title: getDateAndTime(item.dataActiune),
        },
      ],
    };
  });
