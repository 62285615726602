import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest, errorFormatHandler } from "../../helpers/apiHelper";
import {
  closeGlobalToast,
  setGlobalToast,
} from "../reducer/globalComponents.reducer";

import { setLastSavedVerifiedCommands } from "../reducer/general.reducer";
import { resetVerifyCommandProducts } from "../reducer/avizPicking.reducer";
import { VerifyCommandProductsListInterface } from "../interfaces/avizPicking.interface";

const handleSetGlobalToast = (
  dispatch: any,
  message?: string,
  severity = "success",
) => {
  if (message) {
    dispatch(setGlobalToast({ message, severity }));
  } else {
    dispatch(closeGlobalToast());
  }
};

export const getVerifyCommandProducts = createAsyncThunk(
  "/avizPicking/getVerifyCommandProducts",
  async (cellScanned: string | number, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(
        `/avizDetaliiPickingOrderVerificare/${cellScanned}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      dispatch(resetVerifyCommandProducts());

      console.warn("/avizPicking/getVerifyCommandProducts", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getPackingBoxes = createAsyncThunk(
  "/avizPicking/getPackingBoxes",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest("/avizNomCutiiAmbalare", "GET");
      return data;
    } catch (err: any) {
      console.warn("/avizPicking/getPackingBoxes", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postValidateVerification = createAsyncThunk(
  "/avizPicking/valideazaVerificare",
  async (
    {
      params,
    }: {
      params: {
        cellScanned: string | number;
        cutii: any[];
        commandNumber?: string | number | null;
      };
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "/avizValideazaVerificare",
        "POST",
        params,
      );

      const commandNumber = params?.commandNumber?.toString();

      if (commandNumber) {
        dispatch(setLastSavedVerifiedCommands(commandNumber));
      }

      return data;
    } catch (err: any) {
      console.warn("/avizPicking/valideazaVerificare", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getPrintCommandInvoice = createAsyncThunk(
  "/avizPicking/getPrintCommandInvoice",
  async (commandId: string | number, { rejectWithValue, dispatch }) => {
    try {
      await apiRequest(`/avizFacturiComanda/${commandId}`, "GET");
      handleSetGlobalToast(dispatch, "Factura a fost printata!", "success");
    } catch (err: any) {
      console.warn("/avizPicking/getPrintCommandInvoice", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getPrintCommandInvoiceFromHistory = createAsyncThunk(
  "/avizPicking/getPrintCommandInvoiceFromHistory",
  async (commandId: string | number, { rejectWithValue, dispatch }) => {
    try {
      await apiRequest(`/avizFacturiComandaIstoric/${commandId}`, "GET");
      handleSetGlobalToast(dispatch, "Factura a fost printata!", "success");
    } catch (err: any) {
      console.warn("/avizPicking/getPrintCommandInvoiceFromHistory", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getListaCutiiComanda = createAsyncThunk(
  "/avizPicking/listaCutiiComanda",
  async (commandId: string | number, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(
        `/avizListaCutiiComanda/${commandId}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/avizPicking/listaCutiiComanda", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getValidatePacking = createAsyncThunk(
  "/avizPicking/valideazaAmbalare",
  async (
    {
      params,
      callBack,
    }: { params: { cellScanned: string; op: string }; callBack: () => void },
    { rejectWithValue, dispatch },
  ) => {
    try {
      await apiRequest("/avizValideazaAmbalare", "POST", {
        ...params,
      });
      callBack();
    } catch (err: any) {
      console.warn("/avizPicking/valideazaAmbalare", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getDeliveryArrangement = createAsyncThunk(
  "/avizPicking/deliveryArrangement",
  async (
    params: {
      page: number;
      perPage: number;
      status: string;
      facturi?: string;
      ruta?: string;
      deliveryPoint?: string;
      commandNumber?: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    const {
      page,
      perPage,
      status,
      deliveryPoint,
      facturi,
      ruta,
      commandNumber,
    } = params;
    try {
      const { data } = await apiRequest(
        `/avizPickingOrderWeb?page=${page}&per_page=${perPage}&status=${status}&facturi=${facturi}&ruta=${ruta}&deliveryPoint=${deliveryPoint}&commandNumber=${commandNumber}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/avizPicking/deliveryArrangement", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postValidateQuantityVerification = createAsyncThunk(
  "/avizPicking/valideazaVerificareCantitate",
  async (
    {
      params,
      callBack,
    }: {
      params: {
        quantity?: number | string | null;
        commandCode: string;
        scannedCode?: string;
        productId: string;
        type: string;
        product: VerifyCommandProductsListInterface;
        productsAdded?: {
          lot: string | number | null;
          data_exp: Date | null;
          cantitate: string | number | null;
        }[];
        totalQuantity?: number;
      };
      callBack: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      await apiRequest("/avizValideazaVerificareCantitate", "POST", params);
      dispatch(getVerifyCommandProducts(params.commandCode));
      callBack();
    } catch (err: any) {
      console.warn("/avizPicking/valideazaVerificareCantitate", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getCorrectVerification = createAsyncThunk(
  "/avizPicking/correctVerification",
  async (
    {
      params,
      callBack,
    }: { params: { op: string | number }; callBack?: () => void },
    { rejectWithValue, dispatch },
  ) => {
    try {
      await apiRequest(`/avizCorectieComanda/${params.op}`, "GET");
      callBack && callBack();
    } catch (err: any) {
      console.warn("/avizPicking/correctVerification", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getPrintAWBPackingBoxes = createAsyncThunk(
  "/avizPicking/getPrintAWBPackingBoxes",
  async (
    {
      params,
      callBack,
    }: {
      params: {
        commandId: string | number;
      };
      callBack?: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { commandId } = params;
    try {
      const { data } = await apiRequest(
        `/avizListaCutiiComanda/${commandId}`,
        "GET",
      );
      callBack && callBack();
      return data;
    } catch (err: any) {
      console.warn("/avizPicking/getPrintAWBPackingBoxes", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postValidateAWBVerification = createAsyncThunk(
  "/avizPicking/rePrintAwb",
  async (
    {
      params,
      callback,
    }: {
      params: {
        op: string | number;
        cutii: any[];
      };
      callback: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(`/avizRePrintAwb`, "POST", params);
      callback && callback();
      dispatch(resetVerifyCommandProducts());
      return data;
    } catch (err: any) {
      console.warn("/avizPicking/rePrintAwb", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getReprintAWB = createAsyncThunk(
  "/avizPicking/getReprintAWB",
  async (
    {
      params,
      callBack,
    }: {
      params: {
        idNod: string | number;
      };
      callBack?: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { idNod } = params;
    try {
      const { data } = await apiRequest(`/avizGenerateBarcode/${idNod}`, "GET");
      callBack && callBack();
      return data;
    } catch (err: any) {
      console.warn("/avizPicking/getReprintAWB", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getDeliveryInvoices = createAsyncThunk(
  "/avizPicking/getDeliveryInvoices",
  async (
    {
      params,
    }: {
      params: {
        op: string | number;
      };
    },
    { rejectWithValue, dispatch },
  ) => {
    const { op } = params;
    try {
      const { data } = await apiRequest(
        `/avizFacturiDispozitieLivrare/${op}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/avizPicking/getDeliveryInvoices", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchCommandHistory = createAsyncThunk(
  "/avizPicking/fetchCommandHistory",
  async (
    params: {
      page: number;
      per_page: number;
      facturi?: string;
      ruta?: string;
      deliveryPoint?: string;
      commandNumber?: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/avizIstoricComanda", "GET", params);
      return data;
    } catch (err: any) {
      console.warn("/avizPicking/fetchCommandHistory", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);
