import React, { useEffect, useState } from "react";
import TableWidget from "../../../components/tables/TableWidget";
import {
  getReturnDifferencesTableData,
  returnsDifferencesTableHeader,
} from "./tableConfigs/returnDifferencesTableConfig";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getReturnDifferences } from "../../../redux/api/returning.api";
import useRoutesHelper from "../../../hooks/useRoutesHelper";
import { tableLocalCategorySearch } from "../../../components/tables/tableHelper";
import { TableActions } from "../../../components/tables/tableContext/TableContext";
import objectParsers from "../../../utils/objectParsers";

function ReturnDifferences() {
  const dispatch = useAppDispatch();

  const { routeParams } = useRoutesHelper();

  const {
    returning: {
      returnDifferences: {
        data: returnDifferencesData,
        isLoadingReturnDifferences,
      },
    },
  } = useAppSelector((state) => ({
    returning: state.returning,
  }));

  const [tableData, setTableData] = useState<any>([]);

  useEffect(() => {
    if (routeParams.id) {
      dispatch(getReturnDifferences(routeParams.id));
    }
  }, [dispatch, routeParams.id]);

  useEffect(() => {
    if (returnDifferencesData.length > 0) {
      setTableData(returnDifferencesData);
    }
  }, [returnDifferencesData]);

  const handleTableActions = (tableActions: TableActions) => {
    if (objectParsers.hasValue(tableActions.tableSearch)) {
      const filteredTableData = tableLocalCategorySearch(
        returnDifferencesData,
        tableActions.tableSearch,
      );
      setTableData(filteredTableData);
      return;
    }
    setTableData(returnDifferencesData);
  };

  return (
    <TableWidget
      tableTitle={`Diferente retur din punctul de livrare ${routeParams.pctDeLivr}`}
      tableHeaderData={returnsDifferencesTableHeader}
      tableItemsData={getReturnDifferencesTableData(tableData)}
      handleTableActions={handleTableActions}
      withTablePagination={false}
      borderedRow
      tableLoading={isLoadingReturnDifferences}
    />
  );
}

export default ReturnDifferences;
